import { SimpleGrid, TextArea } from "@flpstudio/design-system";
import { useDebouncedCallback } from "@mantine/hooks";
import { forwardRef, useEffect, useState } from "react";

import { LinkPreview } from "@/components/atoms/LinkPreview/LinkPreview";
import { useDocumentLinkMetadata } from "@/hooks/use-documents";
import { appendProtocol, isValidUrl } from "@/utils/url";
import type { SDocumentPromptMetadata } from "types";
import * as styles from "./EditDocumentForm.module.css";

type Props = {
  label?: string;
  placeholder?: string;
  value?: SDocumentPromptMetadata[];
  name?: string;
  onChange?: (metadataArray: SDocumentPromptMetadata[]) => void;
  onBlur?: () => void;
  error?: string;
  required?: boolean;
};

const LinkPreviews = forwardRef<HTMLTextAreaElement, Props>(
  (props: Props, ref) => {
    const [urlArray, setUrlArray] = useState<string[]>([]);

    const convertToUrlArray = useDebouncedCallback((text: string) => {
      setUrlArray(
        Array.from(
          // Remove duplicates
          new Set(
            text
              .trim()
              .split(/[\s,]+/)
              .map((url) => appendProtocol(url)),
          ),
        ),
      );
    }, 1000);

    const { data: generatedMetadataArray, isFetched } = useDocumentLinkMetadata(
      {
        urlArray: urlArray.map((url) => (isValidUrl(url) ? url : "")),
      },
    );

    useEffect(() => {
      if (generatedMetadataArray.length) {
        const updatedMetadataArray = urlArray.map((url) => {
          const metadata = generatedMetadataArray.find(
            (metadata) => metadata?.url === url,
          );

          return {
            url,
            title: !isFetched ? "Fetching title..." : url || "",
            description: !isFetched
              ? "Fetching description..."
              : "No description found",
            favicon: "",
            ...metadata,
          };
        });

        props.onChange?.(updatedMetadataArray);
      }
    }, [generatedMetadataArray, isFetched, urlArray, props.onChange]);

    return (
      <div className={styles.relevantArticles}>
        <TextArea
          {...props}
          ref={ref}
          autosize
          minRows={1}
          defaultValue={props.value?.map((metadata) => metadata.url).join("\n")}
          value={undefined} // We make this an uncontrolled component to improve performance
          onChange={(event) => {
            if (event.target.value.length) {
              convertToUrlArray(event.target.value);
              return;
            }

            props.onChange?.([]);
          }}
          error={props.error}
        />
        {props.value && props.value.length > 0 && (
          <SimpleGrid cols={{ base: 1, lg: 3 }} className="mt-2 gap-2">
            {props.value.map((metadata) => (
              <LinkPreview key={metadata.url} {...metadata} />
            ))}
          </SimpleGrid>
        )}
      </div>
    );
  },
);

export { LinkPreviews };
