import { Button, Modal, Stack, Text } from "@flpstudio/design-system";

type Props = {
  opened: boolean;
  onCancel: () => void;
};

export function MaxBookingsDialog(props: Props) {
  return (
    <Modal
      title="Seems like you’ve booked a lot of sessions"
      opened={props.opened}
      onClose={props.onCancel}
    >
      <Stack>
        <Text>
          Please book new sessions after you finishing some of your upcoming
          sessions.
        </Text>
        <div className="align-right">
          <Button variant="outline" onClick={props.onCancel}>
            Close
          </Button>
        </div>
      </Stack>
    </Modal>
  );
}
