import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import type { Booking, Session } from "types";

const bookingQueryKey = {
  booking: "booking",
  bookingList: "bookingList",
  session: "session",
};

export function getBookingByIdUrl(id?: string) {
  return `/experts/${id}/booking-configs`;
}

export function useAvailableBooking({ identifier }: { identifier?: string }) {
  return useQuery<Booking>({
    queryKey: [bookingQueryKey.booking, identifier],
    queryFn: async () => {
      const { data } = await axios.get(getBookingByIdUrl(identifier));
      return data;
    },
    enabled: !!identifier,
    // If it returns 404, it means the expert is not available for booking
    // so retrying is not necessary
    retry: false,
  });
}

export function getBookingListUrl() {
  return "/booking-sessions";
}

export function useBookingList({ enabled }: { enabled?: boolean }) {
  return useQuery<Session[]>({
    queryKey: [bookingQueryKey.bookingList],
    queryFn: async () => {
      const { data } = await axios.get(getBookingListUrl());
      return data;
    },
    enabled,
  });
}

export function getNewBookingUrl() {
  return "/calendly/new-booking";
}

type NewBookedSessionVariables = {
  expertId: string;
  eventUri: string;
  inviteeUri: string;
};

export function useNewBookedSession(variables?: NewBookedSessionVariables) {
  return useQuery<Session>({
    queryKey: [bookingQueryKey.session, variables?.eventUri],
    queryFn: async () => {
      const { data } = await axios.post(getNewBookingUrl(), variables);
      return data;
    },
    enabled: !!variables,
  });
}
