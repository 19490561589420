import { faCircleExclamation } from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import {
  Group,
  Paper,
  Stack,
  Text,
  Title,
  type TitleProps,
  VisuallyHidden,
} from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import { clsx } from "clsx/lite";
import {
  Fragment,
  type ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import {
  Controller,
  type SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";

import { RichTextEditor } from "@/components/molecules/RichTextEditor/RichTextEditor";
import { useAutoTriggerAction } from "@/hooks/use-auto-trigger-action";
import { useDocumentContentUpdate } from "@/hooks/use-document-mutation";
import { useDocument } from "@/hooks/use-documents";
import type { SDocumentContentPrompt } from "types";
import * as styles from "./EditDocumentForm.module.css";
import { LinkPreviews } from "./LinkPreviews";
import { SoftwareList } from "./SoftwareList";
import { type FormSchema, promptFullSchema } from "./validator";

type EditDocumentFormRef = {
  submit: () => void;
  save: () => void;
};

type Props = {
  name: string;
  documentId: string;
  enableSave?: boolean;
  onSaveStart?: () => void;
  onSaveEnd?: () => void;
  onSubmit?: () => void;
  className?: string;
};

const EditDocumentForm = forwardRef<EditDocumentFormRef, Props>(
  ({ enableSave = true, ...props }, ref) => {
    const { control, formState, getValues, handleSubmit } = useForm<FormSchema>(
      {
        resolver: async (prompt, ...args) => {
          /**
           * NOTE: Due to having a recursive schema and use of `useFieldArray`,
           * the `prompt` object will have this structure:
           * [
           *  {
           *    id: <missing>, <=== This field is required
           *    title: <missing>, <=== This field is required
           *    subPrompts: [{
           *      id: "some-id",
           *      title: "Some title",
           *      description: "Some description",
           *      ...
           *    }]
           *  }
           * ]
           *
           * The missing `id` and `title` fields will not pass the validation.
           * So we need to "fill in" these fields with empty strings.
           */
          prompt.id = "";
          prompt.title = "";

          return await zodResolver(promptFullSchema)(prompt, ...args);
        },
        reValidateMode: "onBlur",
      },
    );

    const { fields: prompts, replace } = useFieldArray({
      control,
      name: "subPrompts",
      keyName: "fieldId",
    });

    const { data: sDocument } = useDocument(props.documentId);

    useEffect(() => {
      if (sDocument) {
        replace(sDocument.content.prompts);
      }
    }, [replace, sDocument]);

    const formRef = useRef<HTMLFormElement>(null);

    const { mutate: mutateDocument, isPending: isMutationPending } =
      useDocumentContentUpdate();

    const saveContent = () => {
      if (!isMutationPending && enableSave) {
        props.onSaveStart?.();

        mutateDocument(
          {
            documentId: props.documentId,
            documentContentPartial: { prompts: getValues("subPrompts") || [] },
          },
          {
            onSettled: () => {
              props.onSaveEnd?.();
            },
          },
        );
      }
    };

    useImperativeHandle(
      ref,
      () => ({
        save: saveContent,
        submit: () => {
          formRef.current?.requestSubmit();
        },
      }),
      [saveContent],
    );

    useEffect(() => {
      const triggerSubmit = (event: KeyboardEvent) => {
        if (event.key === "s" && (event.metaKey || event.ctrlKey)) {
          event.preventDefault();
          saveContent();
        }
      };

      document.addEventListener("keydown", triggerSubmit);

      return () => {
        document.removeEventListener("keydown", triggerSubmit);
      };
    }, [saveContent]);

    useAutoTriggerAction(saveContent);

    const submitContent: SubmitHandler<FormSchema> = () => {
      saveContent();
      props.onSubmit?.();
    };

    if (!sDocument) {
      return null;
    }

    return (
      <form
        ref={formRef}
        name={props.name}
        onSubmit={handleSubmit(submitContent)}
        className={clsx(styles.form, props.className)}
      >
        <Stack>
          <VisuallyHidden component="h1">{prompts[0]?.title}</VisuallyHidden>
          {Object.keys(formState.errors).length > 0 && (
            <Paper className="border border-[--mantine-color-error] border-solid">
              <Group>
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  className="text-[--mantine-color-error]"
                />
                <Text className="font-semibold">
                  Please fix the errors below
                </Text>
              </Group>
            </Paper>
          )}
          {prompts[0]?.subPrompts?.map((prompt, promptIndex) => (
            <Paper key={prompt.id} className="-mx-6 lg:mx-0">
              <Stack>
                {(() => {
                  let headingOrder = 2;
                  const nodes: ReactNode[] = [];

                  const renderNodes = (
                    promptProperties: SDocumentContentPrompt,
                    formFieldNamePrefix: string,
                  ) => {
                    nodes.push(
                      <Fragment key={formFieldNamePrefix}>
                        <Group gap={8}>
                          <Title order={headingOrder as TitleProps["order"]}>
                            {promptProperties.title}
                          </Title>
                          {Object.hasOwn(promptProperties, "required") &&
                            !promptProperties.required && (
                              <span className="rounded bg-[--mantine-color-gray-2] px-1 py-[0.125rem] text-[--mantine-color-gray-7] text-xs/normal">
                                Optional
                              </span>
                            )}
                        </Group>
                        {promptProperties.description && (
                          <Text>{promptProperties.description}</Text>
                        )}
                        {Object.hasOwn(promptProperties, "value") && (
                          <Controller
                            control={control}
                            // @ts-ignore TypeScript has trouble inferring the dynamic field name
                            name={`${formFieldNamePrefix}.value`}
                            render={({ field, fieldState }) => (
                              // @ts-ignore TypeScript has trouble inferring the dynamic value prop
                              <RichTextEditor
                                {...field}
                                // Heading order should be lower than the current prompt heading
                                headingOrder={headingOrder + 1}
                                onSave={saveContent}
                                error={fieldState.error?.message}
                              />
                            )}
                          />
                        )}
                        {promptProperties.metadata?.relevantArticles && (
                          <Controller
                            control={control}
                            // @ts-ignore TypeScript has trouble inferring the dynamic field name
                            name={`${formFieldNamePrefix}.metadata.relevantArticles.value`}
                            render={({ field, fieldState }) => (
                              // @ts-ignore TypeScript has trouble inferring the dynamic value prop
                              <LinkPreviews
                                {...field}
                                label="Relevant external links"
                                placeholder="Add relevant articles"
                                error={fieldState.error?.message}
                                required={
                                  promptProperties.metadata?.relevantArticles
                                    ?.required
                                }
                              />
                            )}
                          />
                        )}
                        {promptProperties.metadata?.recommendations && (
                          <Controller
                            control={control}
                            // @ts-ignore TypeScript has trouble inferring the dynamic field name
                            name={`${formFieldNamePrefix}.metadata.recommendations.value`}
                            render={({ field, fieldState }) => (
                              // @ts-ignore TypeScript has trouble inferring the dynamic value prop
                              <SoftwareList
                                {...field}
                                label="Recommended software"
                                placeholder="Type to search, then select"
                                error={fieldState.error?.message}
                                required={
                                  promptProperties.metadata?.recommendations
                                    ?.required
                                }
                              />
                            )}
                          />
                        )}
                      </Fragment>,
                    );
                    if (promptProperties.subPrompts) {
                      headingOrder++;
                      promptProperties.subPrompts.forEach(
                        (subPrompt, subPromptIndex) => {
                          renderNodes(
                            subPrompt,
                            `${formFieldNamePrefix}.subPrompts.${subPromptIndex}`,
                          );
                        },
                      );
                    }
                  };

                  renderNodes(prompt, `subPrompts.0.subPrompts.${promptIndex}`);

                  return nodes;
                })()}
              </Stack>
            </Paper>
          ))}
        </Stack>
      </form>
    );
  },
);

export { EditDocumentForm, type EditDocumentFormRef };
