import { Button } from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";
import { clsx } from "clsx/lite";
import { useEffect } from "react";

import { useAuth } from "@/hooks/use-auth";
import { useAvailableBooking } from "@/hooks/use-booking";
import { useBookingSearchParams } from "@/hooks/use-page-search-params";
import { AddPaymentMethodDialog } from "./AddPaymentMethodDialog";
import { CalendlyDialog } from "./CalendlyDialog";
import { MaxBookingsDialog } from "./MaxBookingsDialog";
import { UnpaidBookingDialog } from "./UnpaidBookingDialog";

type Props = {
  expertIdentifier: string;
  className?: string;
};

export function Booking(props: Props) {
  const { user } = useAuth();
  const { isStartBooking, clearStartBooking, generateStartBookingLink } =
    useBookingSearchParams();
  const [addPaymentMethodDialogOpened, addPaymentMethodDialogHandlers] =
    useDisclosure();
  const [maxBookingsDialogOpened, maxBookingsDialogHandlers] = useDisclosure();
  const [unpaidBookingDialogOpened, unpaidBookingDialogHandlers] =
    useDisclosure();
  const [calendlyDialogOpened, calendlyDialogHandlers] = useDisclosure();

  const isSelf = props.expertIdentifier === (user?.urlId || user?.id);

  // Prevent booking session with self
  const { data: availableBookingData } = useAvailableBooking({
    identifier: isSelf ? "" : props.expertIdentifier,
  });

  // Auto open calendly dialog if start booking param is present
  useEffect(() => {
    if (
      isStartBooking &&
      availableBookingData?.calendlySchedulingUrl &&
      availableBookingData.paymentAdded
    ) {
      calendlyDialogHandlers.open();
    }
  }, [isStartBooking, availableBookingData, calendlyDialogHandlers]);

  // Render button only if booking data is available
  if (!availableBookingData) {
    return null;
  }
  return (
    <>
      <Button
        className={clsx("ml-auto h-fit px-4 py-2", props.className)}
        classNames={{ label: "flex-col font-normal" }}
        onClick={() => {
          if (availableBookingData.unpaidInvoiceLink) {
            unpaidBookingDialogHandlers.open();
            return;
          }

          if (!availableBookingData.paymentAdded) {
            addPaymentMethodDialogHandlers.open();
            return;
          }

          if (availableBookingData.reachedMaxUpcomingSessions) {
            maxBookingsDialogHandlers.open();
            return;
          }

          calendlyDialogHandlers.open();
        }}
      >
        <span>Book a session</span>
        <span className="text-xs/normal">
          {availableBookingData.price.currency}{" "}
          {availableBookingData.price.amount} for{" "}
          {availableBookingData.durationMinute} min
        </span>
      </Button>
      {!availableBookingData.paymentAdded && (
        <AddPaymentMethodDialog
          opened={addPaymentMethodDialogOpened}
          onCancel={addPaymentMethodDialogHandlers.close}
          cancelCallbackUrl={window.location.href}
          successCallbackUrl={generateStartBookingLink(window.location.href)}
        />
      )}
      {availableBookingData.reachedMaxUpcomingSessions && (
        <MaxBookingsDialog
          opened={maxBookingsDialogOpened}
          onCancel={maxBookingsDialogHandlers.close}
        />
      )}
      {availableBookingData.unpaidInvoiceLink && (
        <UnpaidBookingDialog
          invoiceLink={availableBookingData.unpaidInvoiceLink}
          opened={unpaidBookingDialogOpened}
          onCancel={unpaidBookingDialogHandlers.close}
        />
      )}
      {availableBookingData.calendlySchedulingUrl && (
        <CalendlyDialog
          expertIdentifier={props.expertIdentifier}
          calendlySchedulingUrl={availableBookingData.calendlySchedulingUrl}
          opened={calendlyDialogOpened}
          onClose={() => {
            clearStartBooking();
            calendlyDialogHandlers.close();
          }}
        />
      )}
    </>
  );
}
