import {
  faChartPieSimpleCircleDollar,
  faFileLines,
  faPresentationScreen,
} from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import { Button, Group, Paper, Stack, Text } from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import GoogleLogo from "@/assets/logos/google.png";
import MicrosoftLogo from "@/assets/logos/microsoft.png";
import { useAuth } from "@/hooks/use-auth";
import { useGoogleSSO } from "@/hooks/use-google-sso";
import { useMicrosoftSSO } from "@/hooks/use-microsoft-sso";
import { paths } from "@/routes/paths";

const GOOGLE = "GOOGLE";
const MICROSOFT = "MICROSOFT";

type Initiator = "GOOGLE" | "MICROSOFT" | null;

const SignIn = () => {
  const { signIn, user } = useAuth();
  const [initiator, setInitiator] = useState<Initiator>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const { initiateSSO: initiateGoogleSSO } = useGoogleSSO();
  const { initiateSSO: initiateMicrosoftSSO } = useMicrosoftSSO();

  useEffect(() => {
    if (initiator) {
      if (initiator === GOOGLE) {
        initiateGoogleSSO({
          onSuccess: async (response) => {
            try {
              await signIn({
                accessToken: response.accessToken,
                token: response.credentialToken,
                signInType: "GOOGLE_SSO",
              });
              setInitiator(null);
            } catch (error) {
              const errorObj = error as Error;
              setErrorMessage(errorObj.message);
              setInitiator(null);
            }
          },
          onCancel() {
            setInitiator(null);
          },
          onDismiss() {
            setInitiator(null);
          },
        });
      } else {
        initiateMicrosoftSSO({
          onSuccess: async (response) => {
            try {
              await signIn({
                token: response.idToken,
                accessToken: response.accessToken,
                signInType: "MICROSOFT_SSO",
              });
              setInitiator(null);
            } catch (error) {
              const errorObj = error as Error;
              setErrorMessage(errorObj.message);
              setInitiator(null);
            }
          },
          onCancel() {
            setInitiator(null);
          },
        });
      }
    }
  }, [initiator, initiateGoogleSSO, initiateMicrosoftSSO, signIn]);

  if (user) {
    return <Navigate to={paths.root} />;
  }

  return (
    <Paper className="w-full max-w-[30rem]">
      <Stack className="gap-6">
        <Text component="h1" className="font-semibold text-2xl/normal">
          Continue with Guidestack
        </Text>
        <Button
          variant="outline"
          leftSection={
            <img src={GoogleLogo} alt="Google logo" className="size-6" />
          }
          onClick={() => setInitiator(GOOGLE)}
          loading={initiator === GOOGLE}
          disabled={initiator === MICROSOFT}
        >
          Continue with Google
        </Button>
        <Button
          variant="outline"
          leftSection={
            <img src={MicrosoftLogo} alt="Microsoft logo" className="size-6" />
          }
          onClick={() => setInitiator(MICROSOFT)}
          loading={initiator === MICROSOFT}
          disabled={initiator === GOOGLE}
        >
          Continue with Microsoft
        </Button>
        {errorMessage && (
          <Text className="text-center text-[--mantine-color-error]">
            {errorMessage}
          </Text>
        )}
        <Stack component="ul" className="m-0 list-none flex-col p-0">
          <Group component="li" className="flex-nowrap items-start">
            <FontAwesomeIcon
              icon={faFileLines}
              title="file text icon"
              className="size-6 shrink-0 text-[--mantine-primary-color-filled]"
            />
            <Text className="text-[--mantine-color-gray-7]">
              Leverage community knowledge through expert-generated project
              documentation
            </Text>
          </Group>
          <Group component="li" className="flex-nowrap items-start">
            <FontAwesomeIcon
              icon={faPresentationScreen}
              title="presentation screen icon"
              className="size-6 shrink-0 text-[--mantine-primary-color-filled]"
            />
            <Text className="text-[--mantine-color-gray-7]">
              Book advisory sessions directly with experts with specialized IT
              knowledge
            </Text>
          </Group>
          <Group component="li" className="flex-nowrap items-start">
            <FontAwesomeIcon
              icon={faChartPieSimpleCircleDollar}
              title="pie chart icon"
              className="size-6 shrink-0 text-[--mantine-primary-color-filled]"
            />
            <Text className="text-[--mantine-color-gray-7]">
              Share your own expert documentation and get paid for qualified
              submissions
            </Text>
          </Group>
        </Stack>
      </Stack>
    </Paper>
  );
};

export { SignIn };
