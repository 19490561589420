import {
  Button,
  Container,
  Paper,
  Stack,
  Text,
  Title,
} from "@flpstudio/design-system";
import { Link } from "react-router-dom";

import { Logo } from "@/components/atoms/Logo/Logo";
import { paths } from "@/routes/paths";

export function NotFound() {
  return (
    <div className="bg-[url(@/assets/backgrounds/streamline.png)] bg-bottom bg-cover">
      <Container className="flex min-h-screen flex-col">
        <header>
          <Logo />
        </header>
        <main className="-mx-6 mt-4 flex justify-center lg:m-0 lg:mt-6">
          <Paper className="w-full">
            <Stack>
              <Title
                component="h1"
                className="text-7xl text-[--mantine-color-gray-5]"
              >
                4 😮 4
              </Title>
              <Title component="h2" className="text-2xl/normal">
                We can't find the page you're looking for
              </Title>
              <Text className="text-[--mantine-color-gray-7]">
                Please click below to go back to the dashboard.
              </Text>
              <Button
                variant="outline"
                className="self-baseline"
                component={Link}
                to={paths.root}
              >
                Back to dashboard
              </Button>
            </Stack>
          </Paper>
        </main>
      </Container>
    </div>
  );
}
