import {
  faArrowRightFromBracket,
  faCircleQuestion,
  faUser,
} from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import {
  Anchor,
  Avatar,
  Button,
  Flex,
  Menu,
  Modal,
  Text,
  UnstyledButton,
} from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDisclosure } from "@mantine/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";

import { useAuth } from "@/hooks/use-auth";
import { paths } from "@/routes/paths";
import { ClientOnboardingCallout } from "./ClientOnboardingCallout";
import { ExpertOnboardingCallout } from "./ExpertOnboardingCallout";

type Props = {
  className?: string;
};

const MAIN_MENU_ITEM_CLASSNAME =
  "mb-2 h-10 px-6 first-of-type:rounded-t-none last-of-type:rounded-b-none";
const SECONDARY_MENU_ITEM_CLASSNAME =
  "px-6 py-0 text-[--mantine-color-gray-5] text-xs/normal first-of-type:rounded-t-none last-of-type:rounded-b-none hover:bg-transparent hover:underline";

function UserMenu(props: Props) {
  const { user, deleteUser, signOut } = useAuth();
  const [signOutModalOpened, signOutModalHandlers] = useDisclosure();

  const queryClient = useQueryClient();

  return (
    <>
      <Menu position="top-end">
        <Menu.Target>
          <UnstyledButton aria-label="User menu" className={props.className}>
            <Avatar
              src={user?.profileImage}
              alt="Profile"
              className="size-8 min-w-0 lg:size-10"
            />
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown className="py-4">
          {user?.expertProfile && <ExpertOnboardingCallout />}
          {user?.clientProfile && <ClientOnboardingCallout />}
          <Menu.Item
            component={Link}
            to={paths.profile.root}
            className={MAIN_MENU_ITEM_CLASSNAME}
          >
            <FontAwesomeIcon className="mr-2" icon={faUser} />
            <Text span>Profile{user?.expertProfile ? " & documents" : ""}</Text>
          </Menu.Item>
          <Menu.Item
            component={Link}
            to={paths.help}
            className={MAIN_MENU_ITEM_CLASSNAME}
          >
            <FontAwesomeIcon className="mr-2" icon={faCircleQuestion} />
            <Text span>Help</Text>
          </Menu.Item>
          <Menu.Item
            onClick={signOutModalHandlers.open}
            className={MAIN_MENU_ITEM_CLASSNAME}
          >
            <FontAwesomeIcon className="mr-2" icon={faArrowRightFromBracket} />
            <Text span>Sign out</Text>
          </Menu.Item>
          <Menu.Item
            component={Link}
            to={paths.termsOfService}
            className={SECONDARY_MENU_ITEM_CLASSNAME}
          >
            Guidestack Terms of Service
          </Menu.Item>
          <Menu.Item
            component={Anchor}
            href="https://flp.studio"
            target="_blank"
            rel="noopener noreferrer"
            className={SECONDARY_MENU_ITEM_CLASSNAME}
          >
            Fujitsu Launchpad Terms of Service
          </Menu.Item>
          <Menu.Item
            component={Link}
            to={paths.privacyPolicy}
            className={SECONDARY_MENU_ITEM_CLASSNAME}
          >
            Privacy Policy
          </Menu.Item>
          {import.meta.env.MODE !== "production" && (
            <Menu.Item
              onClick={() => {
                // TODO: Colocate with deleteUser when useAuth is moved to tanstack-query
                queryClient.clear();
                deleteUser();
              }}
              className={SECONDARY_MENU_ITEM_CLASSNAME}
            >
              Delete account (Dev only)
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
      <Modal
        opened={signOutModalOpened}
        onClose={signOutModalHandlers.close}
        title="Sign out of Guidestack?"
      >
        <Text>You can sign in again anytime.</Text>
        <Flex className="mt-4 flex-col lg:flex-row-reverse">
          <Button
            onClick={() => {
              // TODO: Colocate with signOut when useAuth is moved to tanstack-query
              queryClient.clear();
              signOut();
            }}
          >
            Sign out
          </Button>
          <Button variant="outline" onClick={signOutModalHandlers.close}>
            Cancel
          </Button>
        </Flex>
      </Modal>
    </>
  );
}

export { UserMenu };
