import { NativeSelect, Tabs, Text } from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import {
  generatePath,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { DocumentListItem } from "@/components/molecules/DocumentListItem/DocumentListItem";
import { DeclineDocumentDialog } from "@/components/organisms/DeclineDocumentDialog/DeclineDocumentDialog";
import { DeleteDocumentDialog } from "@/components/organisms/DeleteDocumentDialog/DeleteDocumentDialog";
import { MoveToDraftDocumentDialog } from "@/components/organisms/MoveToDraftDocumentDialog/MoveToDraftDocumentDialog";
import { PublishDocumentDialog } from "@/components/organisms/PublishDocumentDialog/PublishDocumentDialog";
import { ViewDocumentDialog } from "@/components/organisms/ViewDocumentDialog/ViewDocumentDialog";
import { useAuth } from "@/hooks/use-auth";
import { useDocumentList } from "@/hooks/use-documents";
import { useScreenSize } from "@/hooks/use-screen-size";
import { paths } from "@/routes/paths";

type DocumentAction = "MOVE_TO_DRAFT" | "DELETE" | "PUBLISH" | "DECLINE";

const ExpertDocuments = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLg } = useScreenSize();

  const { user, role } = useAuth();
  const isAdmin = role === "admin";

  const [documentManage, setDocumentManage] = useState<{
    id: string;
    action: DocumentAction | "";
  }>({
    id: "",
    action: "",
  });

  const resetDocumentManage = () => setDocumentManage({ id: "", action: "" });

  const { data: documents, isLoading } = useDocumentList({
    isAdminView: isAdmin,
  });

  const draftDocuments = documents?.filter((d) => d.status === "DRAFT") || [];
  const inReviewDocuments =
    documents?.filter((d) => d.status === "IN_REVIEW") || [];
  const publishedDocuments =
    documents?.filter((d) => d.status === "PUBLISHED") || [];
  const declinedDocuments =
    documents?.filter((d) => d.status === "DECLINED") || [];

  const userDocumentPath = paths.profile.documents;

  const tabs = [
    {
      value: userDocumentPath.root,
      label: `Draft (${draftDocuments.length})`,
    },
    {
      value: userDocumentPath.review,
      label: `Under review (${inReviewDocuments.length})`,
    },
    {
      value: userDocumentPath.published,
      label: `Published (${publishedDocuments.length})`,
    },
    {
      value: userDocumentPath.declined,
      label: `Declined (${declinedDocuments.length})`,
    },
  ];

  // Open the preview modal when the document ID is in the URL
  const [previewOpened, previewHandler] = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const documentId = searchParams.get("id");

  useEffect(() => {
    if (documentId) {
      previewHandler.open();
    }

    return () => previewHandler.close();
  }, [documentId, previewHandler]);

  if (isLoading) {
    return <Skeleton repeat={5} />;
  }

  return (
    <>
      {!isLg && (
        <NativeSelect
          value={location.pathname}
          onChange={(event) => navigate(event.target.value)}
          className="[&_select]:border-none [&_select]:bg-[--mantine-primary-color-0] [&_select]:text-[--mantine-primary-color-filled]"
        >
          {tabs.map((tab) => (
            <option key={tab.value} value={tab.value}>
              {tab.label}
            </option>
          ))}
        </NativeSelect>
      )}
      <Tabs
        withUnderline={false}
        value={location.pathname}
        onChange={(value) => navigate(value as string)}
      >
        {isLg && (
          <Tabs.List className="!gap-0 mb-4 hidden lg:flex">
            {tabs.map((tab) => (
              <Tabs.Tab
                key={tab.value}
                value={tab.value}
                className="data-[active=true]:!font-normal px-4 py-2 data-[active=true]:bg-[--mantine-primary-color-0] data-[active=true]:text-[--mantine-primary-color-filled]"
              >
                {tab.label}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        )}
        <Tabs.Panel value={userDocumentPath.root}>
          <ul className="m-0 p-0">
            {draftDocuments.length ? (
              draftDocuments.map((doc) => (
                <li key={doc.id} className="list-none [&_+_&]:mt-4">
                  <DocumentListItem
                    adminView={false}
                    document={doc}
                    onClick={(id) =>
                      navigate(
                        generatePath(userDocumentPath.edit, {
                          documentId: id,
                        }),
                      )
                    }
                    onDelete={(id) =>
                      setDocumentManage({ id, action: "DELETE" })
                    }
                  />
                </li>
              ))
            ) : (
              <li className="list-none">
                <Text className="text-[--mantine-color-gray-7]">
                  You don't have any documents saved as drafts at this time.
                </Text>
              </li>
            )}
          </ul>
        </Tabs.Panel>
        <Tabs.Panel value={userDocumentPath.review}>
          <ul className="m-0 p-0">
            {inReviewDocuments.length ? (
              inReviewDocuments.map((doc) => {
                const isOwnDocument = user?.id === doc.author?.id;

                return (
                  <li key={doc.id} className="list-none [&_+_&]:mt-4">
                    <DocumentListItem
                      document={doc}
                      adminView={isAdmin}
                      onClick={(documentId) =>
                        setSearchParams({ id: documentId })
                      }
                      {...(isOwnDocument && {
                        onDelete: (id) =>
                          setDocumentManage({ id, action: "DELETE" }),
                        onMoveToDraft: (id) =>
                          setDocumentManage({ id, action: "MOVE_TO_DRAFT" }),
                      })}
                      {...(isAdmin && {
                        onPublish: (id) =>
                          setDocumentManage({ id, action: "PUBLISH" }),
                        onDecline: (id) =>
                          setDocumentManage({ id, action: "DECLINE" }),
                      })}
                    />
                  </li>
                );
              })
            ) : (
              <li className="list-none">
                <Text className="text-[--mantine-color-gray-7]">
                  You don't have any documents under review at this time. Once
                  submitted, documents will appear here before being published
                  by an admin.
                </Text>
              </li>
            )}
          </ul>
        </Tabs.Panel>
        <Tabs.Panel value={userDocumentPath.published}>
          <ul className="m-0 p-0">
            {publishedDocuments.length ? (
              publishedDocuments.map((doc) => (
                <li key={doc.id} className="list-none [&_+_&]:mt-4">
                  <DocumentListItem
                    document={doc}
                    adminView={isAdmin}
                    onClick={(_, documentSlug) => {
                      navigate(
                        generatePath(paths.documentation.viewer, {
                          urlSlug: documentSlug,
                        }),
                      );
                    }}
                  />
                </li>
              ))
            ) : (
              <li className="list-none">
                <Text className="text-[--mantine-color-gray-7]">
                  You don't have any published documents yet. To get started,
                  please select a category, then select a topic to write about.
                </Text>
              </li>
            )}
          </ul>
        </Tabs.Panel>
        <Tabs.Panel value={userDocumentPath.declined}>
          <ul className="m-0 p-0">
            {declinedDocuments.length ? (
              declinedDocuments.map((doc) => (
                <li key={doc.id} className="list-none [&_+_&]:mt-4">
                  <DocumentListItem
                    document={doc}
                    adminView={isAdmin}
                    onClick={(documentId) =>
                      setSearchParams({ id: documentId })
                    }
                  />
                </li>
              ))
            ) : (
              <li className="list-none">
                <Text className="text-[--mantine-color-gray-7]">
                  You don't have any declined documents.
                </Text>
              </li>
            )}
          </ul>
        </Tabs.Panel>
      </Tabs>

      <ViewDocumentDialog
        opened={previewOpened}
        onClose={() => {
          searchParams.delete("id");
          setSearchParams(searchParams);
        }}
        id={documentId || ""}
        status={
          (documentId &&
            documents?.find((doc) => doc.id === documentId)?.status) ||
          ""
        }
        onDecline={(id) => setDocumentManage({ id, action: "DECLINE" })}
        onPublish={(id) => setDocumentManage({ id, action: "PUBLISH" })}
        editUrl={
          (documentId && generatePath(userDocumentPath.edit, { documentId })) ||
          ""
        }
      />

      <DeleteDocumentDialog
        id={documentManage.id}
        opened={documentManage.action === "DELETE"}
        onDelete={() => resetDocumentManage()}
        onCancel={() => resetDocumentManage()}
        onClose={() => resetDocumentManage()}
      />

      <MoveToDraftDocumentDialog
        id={documentManage.id}
        opened={documentManage.action === "MOVE_TO_DRAFT"}
        onMoveToDraft={() => resetDocumentManage()}
        onCancel={() => resetDocumentManage()}
        onClose={() => resetDocumentManage()}
      />

      {isAdmin && (
        <>
          <PublishDocumentDialog
            id={documentManage.id}
            opened={documentManage.action === "PUBLISH"}
            onPublish={() => {
              resetDocumentManage();
              searchParams.delete("id");
              setSearchParams(searchParams);
            }}
            onCancel={() => resetDocumentManage()}
            onClose={() => resetDocumentManage()}
          />

          <DeclineDocumentDialog
            id={documentManage.id}
            opened={documentManage.action === "DECLINE"}
            onDecline={() => {
              resetDocumentManage();
              searchParams.delete("id");
              setSearchParams(searchParams);
            }}
            onCancel={() => resetDocumentManage()}
            onClose={() => resetDocumentManage()}
          />
        </>
      )}
    </>
  );
};

export { ExpertDocuments };
