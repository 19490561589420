import { Button, Flex, Stack, Text } from "@flpstudio/design-system";
import axios from "axios";
import { type FormEvent, useCallback, useEffect, useState } from "react";

import { OptionButton } from "@/components/atoms/OptionButton/OptionButton";
import { topicPrompts } from "@/templates/topic-prompts";
import { clsx } from "clsx/lite";
import type { SDocument, TopicPrompt } from "types";
import * as styles from "./CreateDocumentSelectTopicForm.module.css";

interface Props {
  className?: string;
  onSubmit: (document: SDocument) => void;
  onCancel: () => void;
  /**
   * Whether the form should have sticky controls at the bottom
   */
  withStickyControls?: boolean;
}

const CreateDocumentSelectTopicForm = (props: Props) => {
  const [list, setList] = useState<TopicPrompt[] | null>(null);
  const [showError, setShowError] = useState(false);

  const getList = useCallback(() => {
    axios
      .get<TopicPrompt[]>("/document/prompts/best-practices")
      .then((response) => {
        setList(response.data);
      });
  }, []);

  useEffect(getList, []);

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const target = event.target as typeof event.target & {
        topicId: { value: string };
      };
      const topicId = target.topicId.value;
      const topicTitle = list?.find((item) => item.id === topicId)?.title || "";

      setShowError(!topicId);

      if (topicId) {
        axios
          .post<SDocument>("/documents/best-practices", {
            topicId,
            content: {
              prompts: JSON.parse(
                JSON.stringify(topicPrompts).replaceAll("%topic%", topicTitle),
              ),
            },
          })
          .then((response) => {
            props.onSubmit(response.data);
          });
      }
    },
    [props.onSubmit, list],
  );

  return (
    <form
      name="createDocumentSelectTopic"
      onSubmit={onSubmit}
      className={props.className}
    >
      <Stack>
        <Stack className={styles.formContent}>
          <Text>
            Please select one of the following topics most beneficial to small
            companies learning about IT best practices.
          </Text>
          {list &&
            list.length > 0 &&
            list.map((item) => (
              <OptionButton
                key={item.id}
                label={<Text span>{item.title}</Text>}
                name="topicId"
                value={item.id}
              />
            ))}
          {list && list.length === 0 && (
            <Text className={styles.secondaryText}>
              No topics are available for document creation at this time. Please
              check back later.
            </Text>
          )}
          {showError && (
            <Text className={styles.errorText}>
              Please select an option to proceed
            </Text>
          )}
        </Stack>
        <Flex
          className={clsx(
            styles.formControls,
            props.withStickyControls && styles.withStickyControls,
          )}
        >
          {list && list.length > 0 && (
            <Button type="submit">Start writing</Button>
          )}
          <Button variant="outline" onClick={props.onCancel}>
            Cancel
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export { CreateDocumentSelectTopicForm };
