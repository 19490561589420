import { Button, Checkbox, Flex, Stack, Text } from "@flpstudio/design-system";
import { useEffect, useState } from "react";

import { ExpertTermsOfService } from "@/components/molecules/ExpertTermsOfService/ExpertTermsOfService";
import { useAuth } from "@/hooks/use-auth";
import { useDocumentSubmission } from "@/hooks/use-document-mutation";

type Props = {
  documentId: string;
  onCancel?: () => void;
  onSuccess?: () => void;
  onFail?: () => void;
};

const SubmitDocumentDialog = (props: Props) => {
  const { user, updateUser } = useAuth();
  const { mutateAsync, isPending } = useDocumentSubmission();
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);
  const [isAgreementAccepted, setIsAgreementAccepted] = useState(false);

  const isFirstTimeSubmitter =
    !user?.expertProfile?.documentSubmissionTermsAcceptedAt;

  useEffect(() => {
    if (!isFirstTimeSubmitter) {
      setIsAgreementAccepted(true);
    }
  }, [isFirstTimeSubmitter]);

  const submitDocument = async () => {
    try {
      if (isFirstTimeSubmitter) {
        // TODO: Add a loading state for the user update when it has been converted to Tanstack Query
        // Right now adding await will cause the modal to "freeze" for a moment after the user clicks the submit button
        updateUser({
          expertProfile: {
            documentSubmissionTermsAcceptedAt: new Date().toISOString(),
          },
        });
      }
      await mutateAsync({ documentId: props.documentId });

      props.onSuccess?.();
    } catch (_error) {
      props.onFail?.();
    }
  };

  return (
    <Stack>
      <Text>
        Once submitted, the Guidestack team will review your document and
        request any editing if needed. We will send you an email when approved.
      </Text>
      {isFirstTimeSubmitter && (
        <>
          <div className="h-80 overflow-y-scroll rounded-lg border border-[--mantine-primary-color-filled] border-solid p-4">
            <ExpertTermsOfService
              headingOrder={3}
              onScrolledToBottom={() => setIsCheckboxEnabled(true)}
            />
          </div>
          <Text>
            Please scroll through the entire Guidestack Expert Terms of Service,
            then check the box below to agree to these terms.
          </Text>
          <Checkbox
            label="I agree to the Guidestack Expert Terms of Service"
            onChange={(e) => setIsAgreementAccepted(e.currentTarget.checked)}
            disabled={!isCheckboxEnabled}
          />
        </>
      )}
      <Flex className="flex-col lg:flex-row-reverse">
        <Button
          onClick={submitDocument}
          disabled={!isAgreementAccepted}
          loading={isPending}
        >
          Confirm submission
        </Button>
        <Button variant="outline" onClick={props.onCancel} disabled={isPending}>
          {isFirstTimeSubmitter ? "Cancel" : "Continue editing"}
        </Button>
      </Flex>
    </Stack>
  );
};

export { SubmitDocumentDialog };
