import { Button, Group, Modal, Text, Title } from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";

import { ExpertProfileForm } from "@/components/organisms/ExpertProfileForm/ExpertProfileForm";
import { UserProfile } from "@/components/organisms/UserProfile/UserProfile";
import { useAuth } from "@/hooks/use-auth";
import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";
import { isExpertProfileCompleted } from "@/utils/user-profile";
import * as styles from "./Profile.module.css";

const ExpertProfile = () => {
  const { user } = useAuth();
  const { isModalOpen } = useProfilePageSearchParams();
  const [opened, handlers] = useDisclosure(isModalOpen("myProfile"));

  const isComplete = isExpertProfileCompleted(user);

  if (!user) return null;

  return (
    <>
      <Group className={styles.sectionHeader}>
        <Title order={1} className={styles.title}>
          My profile
        </Title>
        <Button variant="outline" onClick={handlers.open}>
          Edit
        </Button>
      </Group>
      {isComplete && (
        <>
          <UserProfile
            user={user}
            className="mb-4"
            showFullProfileOnClick={false}
          />
          <Text>{user?.expertProfile?.summary}</Text>
        </>
      )}
      {!isComplete && (
        <>
          <Text className={styles.secondaryText}>
            Please add your profile information.
          </Text>
        </>
      )}
      <Modal
        opened={opened}
        onClose={() => {
          /* noop */
        }}
        title="Edit profile"
      >
        <ExpertProfileForm
          onSubmitText="Close"
          onSubmit={handlers.close}
          withStickyControls
        />
      </Modal>
    </>
  );
};

export { ExpertProfile };
