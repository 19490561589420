import {
  Button,
  Group,
  SimpleGrid,
  Text,
  Title,
} from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";

import { Drawer } from "@/components/molecules/Drawer/Drawer";
import { SoftwareForm } from "@/components/organisms/SoftwareForm/SoftwareForm";
import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";
import { useExpertSoftware } from "@/hooks/use-software";
import * as styles from "./Profile.module.css";

const ExpertSoftware = () => {
  const { isModalOpen } = useProfilePageSearchParams();
  const [opened, handlers] = useDisclosure(isModalOpen("software"));
  const { data: softwares, refetch } = useExpertSoftware();

  if (!softwares) return null;

  const hasContents = !!softwares.length;
  const onClose = () => {
    handlers.close();
    // TODO: A better way to handle this is to invalidate the query cache
    // when ExpertSoftwareForm is submitted
    refetch();
  };

  return (
    <>
      <Group className={styles.sectionHeader}>
        <Title order={1} className={styles.title}>
          Software expertise
        </Title>
        <Button variant="outline" onClick={handlers.open}>
          Edit
        </Button>
      </Group>
      {hasContents && (
        <>
          <Text>
            You’ve added {softwares.length} application
            {softwares.length > 1 && "s"} to your expertise.
          </Text>
          <SimpleGrid cols={{ base: 1, lg: 4 }} className={styles.simpleGrid}>
            {softwares.map((software) => (
              <div key={software.id} className={styles.item}>
                <img src={software.icon} alt={software.name} />
                <Text span lineClamp={1}>
                  {software.name}
                </Text>
              </div>
            ))}
          </SimpleGrid>
        </>
      )}
      {!hasContents && (
        <>
          <Text className={styles.secondaryText}>
            Please add software you have expertise in. This information will
            help us request relevant documents and match you with potential
            clients.
          </Text>
        </>
      )}
      <Drawer
        opened={opened}
        onClose={onClose}
        title="Add your software expertise"
      >
        <SoftwareForm
          checked={softwares?.map((s) => s.id)}
          for="expert"
          onClose={onClose}
        />
      </Drawer>
    </>
  );
};

export { ExpertSoftware };
