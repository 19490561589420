import { z as validator } from "zod";

export function isValidUrl(url: string) {
  /**
   * Matches:
   * https://www.subdomain.domain.tld/path
   * http://www.sudomain.domain.tld/path
   * www.subdomain.domain.tld/path
   * subdomain.domain.tld/path
   * subdomain.domain.tld
   * domain.tld
   *
   * NOTE: Based https://www.rfc-editor.org/rfc/rfc1034 TLDs can be up to 63 characters long
   */
  return validator
    .string()
    .regex(
      /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/gi,
    )
    .safeParse(url).success;
}

export function appendProtocol(url: string) {
  return /^(?:(ht|f)tp(s?):\/\/)/i.test(url) ? url : `https://${url}`;
}
