import {
  Button,
  Flex,
  Modal,
  Stack,
  Text,
  TextArea,
} from "@flpstudio/design-system";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { z as validator } from "zod";

import { useAuth } from "@/hooks/use-auth";
import { useDocumentDecline } from "@/hooks/use-document-mutation";

type Props = {
  id: string;
  opened: boolean;
  onDecline: () => void;
  onCancel: () => void;
  onClose: () => void;
};

const schema = validator.object({
  feedback: validator
    .string()
    .trim()
    .min(1, "Please complete this field to proceed")
    .min(10, "Feedback should be at least 10 characters")
    .max(600, "Feedback should be less than 600 characters"),
});

type FormSchema = validator.infer<typeof schema>;

export function DeclineDocumentDialog(props: Props) {
  const { role } = useAuth();
  const { mutateAsync, isPending, isError } = useDocumentDecline({
    isAdminView: role === "admin",
  });

  const { formState, handleSubmit, register, reset } = useForm<FormSchema>({
    resolver: zodResolver(schema),
    reValidateMode: "onBlur",
  });

  const onSubmit: SubmitHandler<FormSchema> = async (data: FormSchema) => {
    await mutateAsync({ documentId: props.id, feedback: data.feedback });
    props.onDecline();
  };

  // Since Modal is always mounted, we need to reset the form when it's opened
  useEffect(() => {
    if (props.opened) {
      reset();
    }
  }, [props.opened, reset]);

  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      title="Confirm decline document?"
    >
      <form name="declineDocument" onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Text>
            Once declined, this prompt will be available for other experts to
            create a document.
          </Text>
          <TextArea
            minRows={4}
            autosize
            aria-label="Feedback for declined document"
            placeholder="Please provide feedback on the document."
            error={formState.errors.feedback?.message}
            disabled={isPending}
            {...register("feedback")}
          />
          {isError && (
            <Text className="text-[--mantine-color-error]">
              Failed to decline document.
            </Text>
          )}
          <Flex className="flex-col lg:flex-row-reverse">
            <Button
              type="submit"
              variant="outline"
              color="red"
              loading={isPending}
              disabled={!props.opened}
            >
              Confirm decline
            </Button>
            <Button
              variant="outline"
              disabled={isPending || !props.opened}
              onClick={props.onCancel}
            >
              Cancel
            </Button>
          </Flex>
        </Stack>
      </form>
    </Modal>
  );
}
