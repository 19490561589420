import { Button, Stack, Text } from "@flpstudio/design-system";

import LinkedIn from "@/assets/logos/linkedin.png";
import * as styles from "./ExpertLinkedIn.module.css";

interface Props {
  className?: string;
  onNext?: () => void;
}

const ExpertLinkedIn = (props: Props) => {
  const onClick = () => {
    // Do LinkedIn stuff here
    props.onNext?.();
  };

  return (
    <Stack className={props.className}>
      <Text className={styles.secondaryText}>
        Connecting your LinkedIn account will give potential clients a more
        transparent and complete image of your expertise.
      </Text>
      <Button
        variant="outline"
        leftSection={
          <img src={LinkedIn} alt="LinkedIn" className={styles.buttonIcon} />
        }
        onClick={onClick}
      >
        Import from LinkedIn
      </Button>
    </Stack>
  );
};

export { ExpertLinkedIn };
