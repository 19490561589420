const paths = {
  // Public paths
  root: "/",
  login: "/login",
  help: "/help",
  termsOfService: "/terms-of-service",
  expertTermsOfService: "/expert-terms-of-service",
  privacyPolicy: "/privacy-policy",
  notFound: "/404",

  // Private paths
  usageAgreement: "/usage-agreement",
  userTypeSelection: "/user-type-selection",
  home: "/home",
  onboarding: {
    expert: "/onboarding/expert",
    client: "/onboarding/client",
  },
  profile: {
    root: "/me",
    documents: {
      root: "/me/documents",
      review: "/me/documents/review",
      published: "/me/documents/published",
      declined: "/me/documents/declined",
      edit: "/me/documents/edit/:documentId",
    },
  },
  documentation: {
    root: "/documents",
    viewer: "/documents/:urlSlug",
  },
  experts: {
    root: "/experts",
    profile: "/experts/:identifier",
  },
  sessions: {
    root: "/sessions",
    completed: "/sessions/completed",
  },
};

export { paths };
