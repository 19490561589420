import { Button, Flex, Modal, Stack, Text } from "@flpstudio/design-system";
import { useEffect } from "react";

import { useStripePaymentCreate } from "@/hooks/use-booking-mutation";

type AddPaymentMethodProps = {
  onCancel: () => void;
  cancelCallbackUrl: string;
  successCallbackUrl: string;
};

function AddPaymentMethod(props: AddPaymentMethodProps) {
  const { data, mutate, isPending } = useStripePaymentCreate();

  useEffect(() => {
    mutate({
      cancelReturnUrl: props.cancelCallbackUrl,
      successReturnUrl: props.successCallbackUrl,
    });
  }, [mutate, props.cancelCallbackUrl, props.successCallbackUrl]);

  return (
    <form
      name="addPayment"
      aria-label="addPayment"
      method="GET"
      action={data?.paymentFormUrl}
    >
      <Stack>
        <Text>
          We require users to add payment information before booking a session.
          You won’t be charged if you don’t book any session with us.
        </Text>
        <Text>
          Guidestack uses Stripe to manage payment. You can update your payment
          anytime in Stripe.
        </Text>
        <Flex className="mt-4 flex-col lg:flex-row-reverse">
          <Button
            type="submit"
            loading={isPending}
            disabled={!data?.paymentFormUrl}
          >
            Add payment
          </Button>
          <Button variant="outline" onClick={props.onCancel}>
            Cancel
          </Button>
        </Flex>
      </Stack>
    </form>
  );
}

type DialogProps = AddPaymentMethodProps & {
  opened: boolean;
};

export const AddPaymentMethodDialog = (props: DialogProps) => {
  return (
    <Modal
      title="Please add a payment method"
      opened={props.opened}
      onClose={props.onCancel}
    >
      <AddPaymentMethod {...props} />
    </Modal>
  );
};
