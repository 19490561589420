import { Paper, Text } from "@flpstudio/design-system";

import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";
import { paths } from "@/routes/paths";
import { StepItem } from "./CalloutStepItem";

type NextStepCalloutProps = {
  hasDocument: boolean;
  hasIndustry: boolean;
  hasSoftware: boolean;
  isProfileSetupDone: boolean;
};

export function ExpertNextStepCallout({
  hasDocument,
  hasIndustry,
  hasSoftware,
  isProfileSetupDone,
}: NextStepCalloutProps) {
  const { generateModalOpenLink } = useProfilePageSearchParams();

  return (
    <Paper className="flex flex-col p-4 lg:p-6">
      <Text className="font-semibold text-xl">Become a Guidestack expert</Text>
      <p className="text-[--mantine-color-gray-7]">
        Complete your profile setup and write at least one document about a
        software or IT strategy topic. Once approved, clients can book paid
        consultations with you.
      </p>
      <Text className="font-semibold text-xl">Next steps</Text>
      <ul className="m-0 mt-4 flex list-none flex-col gap-4 p-0">
        <StepItem
          description="Complete profile setup"
          completed={isProfileSetupDone}
          link={generateModalOpenLink("myProfile")}
        />
        <StepItem
          description="Add your software expertise"
          completed={hasSoftware}
          link={generateModalOpenLink("software")}
        />
        <StepItem
          description="Add your industry expertise"
          completed={hasIndustry}
          link={generateModalOpenLink("industry")}
        />
        <StepItem
          description="Write a document"
          completed={hasDocument}
          link={paths.profile.documents.root}
        />
      </ul>
    </Paper>
  );
}
