import { faAngleLeft } from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import {
  Button,
  Container,
  Group,
  Loader,
  Modal,
  Text,
} from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useRef, useState } from "react";
import {
  type To,
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import {
  EditDocumentForm,
  type EditDocumentFormRef,
} from "@/components/organisms/EditDocumentForm/EditDocumentForm";
import { SubmitDocumentDialog } from "@/components/organisms/SubmitDocumentDialog/SubmitDocumentDialog";
import { SubmitFailDialog } from "@/components/organisms/SubmitFailDialog/SubmitFailDialog";
import { SubmitSuccessDialog } from "@/components/organisms/SubmitSuccessDialog/SubmitSuccessDialog";
import { UserMenu } from "@/components/organisms/UserMenu/UserMenu";
import { useAuth } from "@/hooks/use-auth";
import { useDocument } from "@/hooks/use-documents";
import { paths } from "@/routes/paths";

const EditDocument = () => {
  const { user } = useAuth();
  const { documentId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const formRef = useRef<EditDocumentFormRef>(null);
  const [isEditable, setIsEditable] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [enableSave, setEnableSave] = useState(true);
  const [shouldGoBack, setShouldGoBack] = useState(false);
  const [submitModalOpen, submitModalHandlers] = useDisclosure();
  const [submitSuccessModalOpen, submitSuccessModalHandlers] = useDisclosure();
  const [submitFailModalOpen, submitFailModalHandlers] = useDisclosure();

  const { data: document, error } = useDocument(documentId);

  useEffect(() => {
    // If the document is not found, redirect to 404
    if (error) {
      navigate(paths.notFound, { replace: true });
    }

    if (!document || !user) {
      return;
    }

    // Users should not be able to edit other users' drafts
    if (document.status === "DRAFT" && document.author?.id !== user.id) {
      navigate(paths.notFound, { replace: true });
      return;
    }

    if (document.status === "IN_REVIEW") {
      if (user.isAdmin) {
        // Unless user is an admin, users must move their documents to draft
        // before editing them
        setIsEditable(true);
        return;
      }

      navigate(`${paths.profile.documents.review}?id=${document.id}`, {
        replace: true,
      });
      return;
    }

    if (document.status === "DECLINED") {
      navigate(`${paths.profile.documents.declined}?id=${document.id}`, {
        replace: true,
      });
      return;
    }

    if (document.status === "PUBLISHED") {
      if (user.isAdmin) {
        // Unless user is an admin, users must move their documents to draft
        // before editing them
        setIsEditable(true);
        return;
      }
      navigate(
        generatePath(paths.documentation.viewer, {
          urlSlug: document.urlTitle,
        }),
      );
      return;
    }

    setIsEditable(true);
  }, [document, error, user, navigate]);

  /**
   * The reason this effect is here is that we want the editor to finish saving
   * the document before navigating back. However, if the component is just unmounted
   * before the save is finished, @tanstack/react-query will not have the chance
   * to invalidate the query, causing it to be stale when the user opens the document
   * either for viewing or editing.
   */
  useEffect(() => {
    if (isSaving || !shouldGoBack) {
      return;
    }

    navigate(
      location.key === "default" ? paths.profile.documents.root : (-1 as To),
    );
  }, [shouldGoBack, isSaving, location.key, navigate]);

  const handleBack = () => {
    if (!isSaving) {
      // Stops spamming the back button
      saveEdit();
      setShouldGoBack(true);
    }
  };

  const saveEdit = () => {
    formRef.current?.save();
  };

  const submitForm = () => {
    formRef.current?.submit();
  };

  if (!document || !isEditable || !user) {
    return null;
  }

  return (
    <>
      <header className="sticky top-0 z-10 bg-white lg:shadow-paper">
        <nav className="px-6 py-4">
          <Group className="justify-end">
            <Button
              variant="outline"
              leftSection={<FontAwesomeIcon icon={faAngleLeft} />}
              onClick={handleBack}
              className="mr-auto border-none"
            >
              Back
            </Button>
            {isSaving && (
              <Group gap={8} className="hidden lg:flex">
                <Text className="text-[--mantine-color-gray-5]">Saving</Text>
                <Loader size="sm" color="gray.4" />
              </Group>
            )}
            <Button
              variant="outline"
              onClick={saveEdit}
              className="hidden lg:inline"
            >
              {document.status === "DRAFT" ? "Save as draft" : "Save edits"}
            </Button>
            {document.status === "DRAFT" && (
              <Button onClick={submitForm}>Submit for review</Button>
            )}
            <UserMenu className="hidden lg:inline" />
          </Group>
        </nav>
      </header>
      <Container component="main">
        <EditDocumentForm
          ref={formRef}
          name="editor"
          documentId={document.id}
          enableSave={enableSave}
          onSaveStart={() => setIsSaving(true)}
          onSaveEnd={() => {
            setIsSaving(false);
          }}
          onSubmit={() => {
            submitModalHandlers.open();
            setEnableSave(false);
          }}
        />
      </Container>
      <Modal
        title="Please confirm your document submission"
        opened={submitModalOpen}
        onClose={submitModalHandlers.close}
      >
        <SubmitDocumentDialog
          documentId={document.id}
          onSuccess={() => {
            submitModalHandlers.close();
            submitSuccessModalHandlers.open();
          }}
          onFail={() => {
            submitModalHandlers.close();
            submitFailModalHandlers.open();
          }}
          onCancel={() => {
            submitModalHandlers.close();
            setEnableSave(true);
          }}
        />
      </Modal>
      <Modal
        title="Document submitted and under review"
        opened={submitSuccessModalOpen}
        onClose={() => {
          /* noop */
        }}
      >
        <SubmitSuccessDialog
          onDone={() => navigate(paths.profile.documents.review)}
        />
      </Modal>
      <Modal
        title="Submitting document failed"
        opened={submitFailModalOpen}
        onClose={submitFailModalHandlers.close}
      >
        <SubmitFailDialog
          documentId={document.id}
          onSuccess={() => {
            submitFailModalHandlers.close();
            submitSuccessModalHandlers.open();
          }}
          onFail={submitFailModalHandlers.open}
          onCancel={() => {
            submitFailModalHandlers.close();
            setEnableSave(true);
          }}
        />
      </Modal>
    </>
  );
};

export { EditDocument };
