import { Editor } from "@tinymce/tinymce-react";
import { clsx } from "clsx/lite";
import {
  forwardRef,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { PreSignedImageUploadHandler } from "./ImageUploadHandler";
import * as styles from "./RichTextEditor.module.css";

interface Props {
  className?: string;
  name?: string;
  value?: string;
  onChange?: (value: string) => void;
  onSave?: () => void;
  onBlur?: () => void;
  error?: string;
  headingOrder?: number;
}

const RichTextEditor = forwardRef<Partial<HTMLTextAreaElement>, Props>(
  ({ headingOrder = 1, ...props }, ref) => {
    const id = useId();
    const [isFocused, setIsFocused] = useState(false);

    const tinyMceRef = useRef<Editor>(null);

    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          tinyMceRef.current?.editor?.focus();
        },
      }),
      [],
    );

    return (
      <div
        {...(props.error && {
          "data-error": true,
          "aria-invalid": true,
          "aria-describedby": id,
        })}
        {...(isFocused && { "data-focus": true })}
        className={clsx(styles.wrapper, props.className)}
      >
        <Editor
          ref={tinyMceRef}
          tinymceScriptSrc="/tinymce/tinymce.min.js"
          textareaName={props.name}
          value={props.value}
          onEditorChange={props.onChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false);
            props.onBlur?.();
          }}
          init={{
            setup(editor) {
              // Forward key events to the parent element
              // This is a hack to allow the parent document to capture key events
              // and trigger some actions, like saving the document
              editor.on("keydown", (event) => {
                editor
                  .getContainer()
                  .dispatchEvent(
                    new KeyboardEvent("keydown", { ...event, bubbles: true }),
                  );
              });
              editor.on("keyup", (event) => {
                editor
                  .getContainer()
                  .dispatchEvent(
                    new KeyboardEvent("keyup", { ...event, bubbles: true }),
                  );
              });
            },
            skin: "tinymce-5",
            menubar: false,
            statusbar: false,
            iframe_aria_text: "Rich Text Editor",
            placeholder: "Write your content here",
            plugins:
              "autolink autoresize link lists quickbars quickimage save table",
            quickbars_insert_toolbar: false,
            quickbars_selection_toolbar: false,
            quickbars_image_toolbar: false,
            toolbar:
              "undo redo | blocks bold italic underline strikethrough | numlist bullist link | quickimage table",
            table_toolbar:
              "tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
            // Heading order can only be between 1 and 6
            block_formats: `Title=h${Math.min(headingOrder, 6)}; Text=p;`,
            file_picker_types: "image",
            automatic_uploads: true,
            link_default_target: "_blank",
            link_default_protocol: "https",
            a11y_advanced_options: true,
            save_onsavecallback: props.onSave,
            min_height: 240,
            content_style:
              // Body color is var(--mantine-color-gray-9) from the theme
              "body{font-family:Inter,sans-serif;line-height:1.5;color:#1D293A}" +
              // Link color is var(--mantine-color-violet-6) from the theme, the primary color
              "a{color:#7950f2}" +
              "b,strong,h1,h2,h3,h4,h5,h6{font-weight:600;}" +
              "h1,h2,h3,h4,h5,h6,p{margin-block:1rem}" +
              "h1{font-size:1.5rem}" +
              "h2{font-size:1.25rem}" +
              "h3{font-size:1rem}" +
              "h4{font-size:1rem}" +
              "h5{font-size:1rem}" +
              "h6{font-size:1rem}" +
              "img{max-width:100%;height:auto;}" +
              `table[border]:not([border="0"]):not([style*="border-color"]) td,
              table[border]:not([border="0"]):not([style*="border-color"]) th{border-color:#D0D5DD}` +
              // Placeholder color is var(--mantine-color-gray-5) from the theme
              ".mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before{color:#98A2B3}",
            font_css:
              "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap",
            images_upload_handler: PreSignedImageUploadHandler,
            // Paste as plain text to prevent malicious code injection and bad formatting
            paste_as_text: true,
          }}
        />
        {props.error && (
          <p className={styles.error} id={id}>
            {props.error}
          </p>
        )}
      </div>
    );
  },
);

export { RichTextEditor };
