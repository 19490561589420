import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import type { Software } from "types";

type UseSoftwareQueryParams = {
  includeDocuments?: boolean;
  softwareIds?: string;
};

export function getSoftwaresUrl() {
  return "/softwares";
}

export function useSoftware(queryParams: UseSoftwareQueryParams = {}) {
  return useQuery({
    queryKey: ["softwares", queryParams],
    queryFn: async () => {
      const { data } = await axios.get<Software[]>(getSoftwaresUrl(), {
        params: queryParams,
      });
      return data.sort((nameA, nameB) => nameA.name.localeCompare(nameB.name));
    },
  });
}

const clientSoftwareQueryKey = ["clientSoftwares"];
export function useClientSoftware() {
  return useQuery({
    queryKey: clientSoftwareQueryKey,
    queryFn: async () => {
      const { data } = await axios.get<Software[]>("/client/softwares");
      return data;
    },
  });
}

const expertSoftwareQueryKey = ["expertSoftwares"];
export function useExpertSoftware() {
  return useQuery<Software[]>({
    queryKey: expertSoftwareQueryKey,
    queryFn: async () => {
      const { data } = await axios.get("/expert/softwares");
      return data;
    },
  });
}

export function useSoftwareMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: {
      softwareId: string;
      for: "client" | "expert";
      action: "put" | "delete";
    }) => {
      const request = axios[variables.action];

      return request(`/${variables.for}/softwares/${variables.softwareId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: clientSoftwareQueryKey,
      });
      queryClient.invalidateQueries({
        queryKey: expertSoftwareQueryKey,
      });
    },
    onError: () => {
      //TODO: log error and show notification
    },
  });
}
