import { useIdle, useInterval, useMounted } from "@mantine/hooks";
import { useEffect } from "react";

const MIN_TRIGGER_INTERVAL = 1000;

/**
 * Does two things:
 * 1. Triggers an action every `triggerInterval` milliseconds
 * 2. Triggers an action when the user is idle for `idleTimeout` milliseconds
 *
 * @param action The function to run
 * @param triggerInterval The time in milliseconds to wait before running the action. Must be at least 1000ms. Should be greater than `idleTimeout`.
 * @param idleTimeout The time in milliseconds to wait before running the action when the user is idle
 */
const useAutoTriggerAction = (
  // biome-ignore lint/suspicious/noExplicitAny: action can be any function
  action: (...args: any[]) => void | Promise<void>,
  triggerInterval = 30000,
  idleTimeout = 5000,
) => {
  const isMounted = useMounted();
  const isIdle = useIdle(idleTimeout, { events: ["keyup"] });
  const interval = useInterval(action, triggerInterval);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Putting all dependencies in the array causes an infinite loop
  useEffect(() => {
    if (isMounted) {
      if (triggerInterval < MIN_TRIGGER_INTERVAL) {
        console.error(
          `The triggerInterval must be at least ${MIN_TRIGGER_INTERVAL}ms.`,
        );
        return;
      }

      if (triggerInterval <= idleTimeout) {
        console.error(
          "The triggerInterval must be greater than the idleTimeout.",
        );
        return;
      }

      if (isIdle && interval.active) {
        interval.stop();
        action();
      } else {
        interval.start();
      }
    }

    return interval.stop;
  }, [isMounted, isIdle, triggerInterval]);
};

export { useAutoTriggerAction };
