import {
  Button,
  Flex,
  NativeSelect,
  Radio,
  Stack,
  Text,
  TextArea,
} from "@flpstudio/design-system";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { z as validator } from "zod";

import { useAuth } from "@/hooks/use-auth";
import { clsx } from "clsx/lite";
import type { ITLevel, ProductivitySuite, User } from "types";

const SUMMARY_MIN_LENGTH = 20;
const SUMMARY_MAX_LENGTH = 500;

export const IT_LEVELS: { label: string; value: ITLevel }[] = [
  {
    label: "I’m a beginner",
    value: "BEGINNER",
  },
  {
    label: "I have some knowledge",
    value: "BASIC",
  },
  {
    label: "I’m quite comfortable",
    value: "HIGH",
  },
];
export const PRODUCTIVITY_SUITES: {
  label: string;
  value: ProductivitySuite;
}[] = [
  {
    label: "Google Suite",
    value: "GOOGLE_SUITE",
  },
  {
    label: "Microsoft 365",
    value: "MICROSOFT_365",
  },
  {
    label: "Neither of these",
    value: "NEITHER",
  },
] as const;

const noSelectionErrorMsg = "Please select an option to proceed";
const schema = validator.object({
  itLevel: validator
    .string({ message: noSelectionErrorMsg })
    .min(1, noSelectionErrorMsg),
  productivitySuite: validator
    .string({ message: noSelectionErrorMsg })
    .min(1, noSelectionErrorMsg),
  lookingFor: validator
    .string()
    .trim()
    .max(
      SUMMARY_MAX_LENGTH,
      `Summary should be ${SUMMARY_MAX_LENGTH} characters or less`,
    )
    .optional()
    .or(validator.literal("")),
  itSummary: validator
    .string()
    .trim()
    .min(1, "Please complete this field to proceed")
    .min(
      SUMMARY_MIN_LENGTH,
      `Summary should be more than ${SUMMARY_MIN_LENGTH} characters`,
    )
    .max(
      SUMMARY_MAX_LENGTH,
      `Summary should be ${SUMMARY_MAX_LENGTH} characters or less`,
    ),
});

type FormSchema = validator.infer<typeof schema>;

type Props = {
  className?: string;
  onBack?: () => void;
  onSubmit: () => void;
  onSubmitText: string;
  /**
   * Whether the form should have sticky controls at the bottom
   */
  withStickyControls?: boolean;
};

export function ITSetupForm(props: Props) {
  const { user, updateUser } = useAuth();
  const { itSummary, productivitySuite, lookingFor } =
    user?.clientProfile?.company || {};
  const itLevel = user?.clientProfile?.itLevel;
  const [selectedSuite, setSelectedSuite] = useState(productivitySuite);

  const { formState, getValues, register, handleSubmit, watch } =
    useForm<FormSchema>({
      defaultValues: {
        itLevel: itLevel || "",
        itSummary: itSummary || "",
        lookingFor: lookingFor || "",
        productivitySuite: productivitySuite || "",
      },
      reValidateMode: "onBlur",
      resolver: zodResolver(schema),
    });

  const itSummaryLength = watch("itSummary").length;
  const lookingForLength = watch("lookingFor")?.length ?? 0;

  const saveData = async () => {
    try {
      await updateUser({
        clientProfile: {
          ...user?.clientProfile,
          ...(getValues("itLevel")
            ? { itLevel: getValues("itLevel") as ITLevel }
            : {}),
          company: {
            ...user?.clientProfile?.company,
            ...(getValues("productivitySuite")
              ? {
                  productivitySuite: getValues(
                    "productivitySuite",
                  ) as ProductivitySuite,
                }
              : {}),
            ...(getValues("itSummary")
              ? { itSummary: getValues("itSummary") }
              : {}),
            ...(getValues("lookingFor")
              ? { lookingFor: getValues("lookingFor") }
              : {}),
          },
        },
      });
    } catch (_error) {}
  };

  const onSubmit: SubmitHandler<FormSchema> = async () => {
    try {
      await saveData();
      props.onSubmit();
    } catch (_error) {}
  };

  return (
    <form
      name="ITSetup"
      onSubmit={handleSubmit(onSubmit)}
      className={props.className}
    >
      <Stack className="gap-4">
        <Flex className="flex-col">
          <label>
            <Text className="mb-2 font-semibold">
              What is your comfort level with IT?
            </Text>
            <Stack>
              {IT_LEVELS.map((level) => (
                <Radio
                  aria-label={level.label}
                  key={level.value}
                  label={level.label}
                  value={level.value}
                  {...register("itLevel", {
                    // Immediately save the data when the option is changed
                    onChange: saveData,
                  })}
                />
              ))}
            </Stack>
            {formState.errors.itLevel && (
              <Text c="red" className="mt-2 text-xs">
                {formState.errors.itLevel.message}
              </Text>
            )}
          </label>

          <div>
            <NativeSelect
              {...register("productivitySuite")}
              label={
                <Text className="mb-2 font-semibold">
                  Which productivity suite does your company use?
                </Text>
              }
              value={selectedSuite}
              error={formState.errors.productivitySuite?.message}
              classNames={{
                input: !selectedSuite
                  ? "text-[color:--mantine-color-gray-5] mb-2"
                  : "mb-2",
              }}
              onChange={(event) =>
                setSelectedSuite(event.target.value as ProductivitySuite)
              }
            >
              <>
                <option value="">Please select</option>
                {PRODUCTIVITY_SUITES.map((suite) => (
                  <option key={suite.value} value={suite.value}>
                    {suite.label}
                  </option>
                ))}
              </>
            </NativeSelect>
          </div>

          <div>
            <TextArea
              label={
                <Text className="mb-0 font-semibold">
                  How does your company currently manage IT?
                </Text>
              }
              description={
                <Text
                  component="span"
                  className="mb-2 inline-block text-[color:--mantine-color-gray-7]"
                >
                  Please explain how your company manages core aspects of IT
                  including devices (e.g., laptops, phones), email, and software
                  (e.g., SaaS licenses).
                </Text>
              }
              inputWrapperOrder={["label", "description", "input", "error"]}
              minRows={4}
              autosize
              placeholder="Briefly explain how IT is managed in your company"
              error={formState.errors.itSummary?.message}
              {...register("itSummary")}
            />
            <Text
              c={itSummaryLength <= SUMMARY_MAX_LENGTH ? "gray.6" : "red"}
              className="pt-1 text-xs"
            >
              {itSummaryLength}/{SUMMARY_MAX_LENGTH} characters
            </Text>
          </div>

          <div>
            <TextArea
              label={
                <Text className="mb-0 inline-block font-semibold">
                  What is the primary reason you came to Guidestack?
                </Text>
              }
              description={
                <>
                  <Text
                    component="span"
                    className="mb-2 inline-block text-[color:--mantine-color-gray-7]"
                  >
                    Please briefly explain the IT-related challenges you are
                    looking for support or advice on.
                  </Text>
                  <span className="mb-1 inline-block rounded bg-[--mantine-color-gray-2] px-1 py-0.5 text-[--mantine-color-gray-7] text-xs">
                    Optional
                  </span>
                </>
              }
              inputWrapperOrder={["label", "description", "input", "error"]}
              minRows={4}
              autosize
              placeholder="Briefly explain what you are looking for"
              error={formState.errors.lookingFor?.message}
              {...register("lookingFor")}
            />
            <Text
              c={lookingForLength <= SUMMARY_MAX_LENGTH ? "gray.6" : "red"}
              className="pt-1 text-xs"
            >
              {lookingForLength}/{SUMMARY_MAX_LENGTH} characters
            </Text>
          </div>

          <Flex
            className={clsx(
              "flex-col bg-white lg:flex-row-reverse lg:justify-between",
              props.withStickyControls &&
                "-mb-6 sticky bottom-0 z-10 bg-white pb-6",
            )}
          >
            <Button
              type="submit"
              variant={props.onSubmitText === "Close" ? "outline" : ""}
            >
              {props.onSubmitText}
            </Button>
            {props.onBack && (
              <Button
                variant="transparent"
                onClick={props.onBack}
                className="ps-0 pe-0"
              >
                Back
              </Button>
            )}
          </Flex>
        </Flex>
      </Stack>
    </form>
  );
}

export function isITSetupCompleted(user: User) {
  const { itSummary, lookingFor, productivitySuite } =
    user.clientProfile?.company || {};

  const itLevel = user.clientProfile?.itLevel;

  const parsed = schema.safeParse({
    itLevel,
    productivitySuite,
    lookingFor,
    itSummary,
  });

  return parsed.success;
}
