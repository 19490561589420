import { Button, Flex, Text } from "@flpstudio/design-system";
import { useEffect } from "react";

import { useDocumentSubmission } from "@/hooks/use-document-mutation";

type Props = {
  documentId: string;
  onCancel?: () => void;
  onSuccess?: () => void;
  onFail?: () => void;
};

const SubmitFailDialog = (props: Props) => {
  const documentSubmission = useDocumentSubmission();

  useEffect(() => {
    if (!documentSubmission.isPending) {
      if (documentSubmission.isSuccess) {
        props.onSuccess?.();
      }

      if (documentSubmission.isError) {
        props.onFail?.();
      }
    }
  }, [documentSubmission, props.onSuccess, props.onFail]);

  const submitDocument = () => {
    documentSubmission.mutate({ documentId: props.documentId });
  };

  return (
    <>
      <Text>We weren't able to submit your document. Please try again.</Text>
      <Flex className="mt-4 flex-col lg:flex-row-reverse">
        <Button onClick={submitDocument}>Retry</Button>
        <Button variant="outline" onClick={props.onCancel}>
          Cancel
        </Button>
      </Flex>
    </>
  );
};

export { SubmitFailDialog };
