import { Button, Group, Modal, Text, Title } from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";

import {
  ClientProfileForm,
  isProfileCompleted,
} from "@/components/organisms/ClientOnboarding/ClientProfileForm";
import { UserProfile } from "@/components/organisms/UserProfile/UserProfile";
import { useAuth } from "@/hooks/use-auth";
import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";

export function EditableProfile() {
  const { user } = useAuth();
  const { isModalOpen } = useProfilePageSearchParams();
  const [opened, handlers] = useDisclosure(isModalOpen("myProfile"));

  if (!user) return null;

  return (
    <>
      <Group className="mb-4 justify-between">
        <Title order={4}>My profile</Title>
        <Button variant="outline" onClick={handlers.open}>
          Edit
        </Button>
      </Group>
      {isProfileCompleted(user) ? (
        <UserProfile user={user} className="mb-4" />
      ) : (
        <Text className="text-[color:--mantine-color-gray-6]">
          Please add your profile information.
        </Text>
      )}
      <Modal
        opened={opened}
        onClose={() => {
          /* noop */
        }}
        title="Edit profile"
      >
        <ClientProfileForm
          onSubmitText="Close"
          onSubmit={handlers.close}
          withStickyControls
        />
      </Modal>
    </>
  );
}
