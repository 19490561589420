import {
  Button,
  Group,
  SimpleGrid,
  Text,
  Title,
} from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";

import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { Drawer } from "@/components/molecules/Drawer/Drawer";
import { SoftwareForm } from "@/components/organisms/SoftwareForm/SoftwareForm";
import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";
import { useClientSoftware } from "@/hooks/use-software";

export function SoftwareInUse() {
  const { isModalOpen } = useProfilePageSearchParams();
  const [opened, handlers] = useDisclosure(isModalOpen("software"));
  const { data: softwares, isLoading } = useClientSoftware();

  if (isLoading) {
    return <Skeleton />;
  }

  const hasContents = !!softwares?.length;

  const onClose = () => {
    handlers.close();
  };

  return (
    <>
      <Group className="mb-4 justify-between">
        <Title order={4}>Software in use</Title>
        <Button variant="outline" onClick={handlers.open}>
          Edit
        </Button>
      </Group>
      {hasContents ? (
        <>
          <Text>
            You’ve added {softwares.length} application
            {softwares.length > 1 && "s"}.
          </Text>
          <SimpleGrid cols={{ base: 1, lg: 4 }} className="mt-4">
            {softwares.map((software) => (
              <div
                key={software.id}
                className="flex h-10 items-center gap-2 rounded-s border-[0.0625rem] border-[color:--mantine-color-gray-3] border-solid px-2 py-0"
              >
                <img
                  src={software.icon}
                  alt={software.name}
                  className="size-6"
                />
                <Text span lineClamp={1}>
                  {software.name}
                </Text>
              </div>
            ))}
          </SimpleGrid>
        </>
      ) : (
        <Text className="text-[color:--mantine-color-gray-6]">
          This information will help experts understand your IT setup and
          provide guidance. It will be visible only to you and any experts you
          directly book consultations with.
        </Text>
      )}
      <Drawer
        opened={opened}
        onClose={onClose}
        title="Add software used by your business"
      >
        <SoftwareForm
          checked={softwares?.map((s) => s.id)}
          for="client"
          onClose={onClose}
        />
      </Drawer>
    </>
  );
}
