import {
  Avatar,
  Badge,
  Flex,
  Group,
  Stack,
  Text,
  UnstyledButton,
} from "@flpstudio/design-system";
import { clsx } from "clsx/lite";

import LinkedInLogo from "@/assets/logos/linkedin.png";
import { useExpertFullProfileSearchParams } from "@/hooks/use-page-search-params";
import { countryList } from "@/utils/country-list";

import type { User } from "types";

function UserLocation(props: { city: string; countryCode: string }) {
  const { city, countryCode } = props;
  return (
    <Text span className="text-[--mantine-color-gray-7] text-sm">
      {[city, countryList.find((country) => country.code === countryCode)?.name]
        .filter(Boolean)
        .join(", ")}
    </Text>
  );
}

function UserIndustries(props: { industries: string[] }) {
  return (
    <Group component="ul" className="m-0 list-none gap-2 p-0">
      {props.industries.map((industry) => (
        <li key={industry}>
          <Badge variant="light" color="gray">
            {industry}
          </Badge>
        </li>
      ))}
    </Group>
  );
}

type UserProfileProps = {
  user: Partial<User>;
  showStatus?: boolean;
  showSummary?: boolean | "truncate";
  className?: string;
  showFullProfileOnClick?: boolean;
};

export function UserProfile(props: UserProfileProps) {
  const { setExpertIdentifier } = useExpertFullProfileSearchParams();

  const {
    city,
    countryCode,
    expertProfile,
    clientProfile,
    industries,
    status,
    summary,
    email,
    linkedinUrl,
  } = props.user;

  const isApproved = status === "APPROVED";
  const linkedin =
    linkedinUrl || expertProfile?.linkedinUrl || clientProfile?.linkedinUrl;
  const userIndustries = industries || expertProfile?.industries || [];
  const userSummary = summary || expertProfile?.summary;
  const { company: clientCompany, jobTitle: clientJobTitle } =
    clientProfile || {};

  const showFullProfile = props.showFullProfileOnClick ?? true;
  const displayFullProfile = () =>
    setExpertIdentifier(props.user.urlId || props.user.id || "");

  return (
    <>
      <Flex
        className={clsx("flex-col lg:flex-row lg:items-start", props.className)}
      >
        {showFullProfile ? (
          <UnstyledButton onClick={displayFullProfile}>
            <Avatar
              src={props.user.profileImage}
              alt="Profile"
              className="size-16 shrink-0"
            />
          </UnstyledButton>
        ) : (
          <Avatar
            src={props.user.profileImage}
            alt="Profile"
            className="size-16 shrink-0"
          />
        )}
        <Stack className="gap-2">
          <Group className="gap-1">
            <Text
              span
              className={clsx(
                "font-medium text-xl",
                showFullProfile &&
                  "cursor-pointer hover:text-[--mantine-primary-color-filled-hover] hover:underline",
              )}
              {...(showFullProfile && { onClick: displayFullProfile })}
            >
              {[props.user.firstName, props.user.lastName].join(" ").trim() ||
                props.user.name}
            </Text>
            {props.user.status && props.showStatus && (
              <Badge variant="light" color={isApproved ? "green" : "gray"}>
                {isApproved ? "Approved" : "Joined"}
              </Badge>
            )}
            {linkedin && (
              <UnstyledButton
                component="a"
                href={linkedin}
                target="_blank"
                rel="noreferrer noopener"
                className="size-[1.125rem]"
              >
                <img
                  src={LinkedInLogo}
                  alt="LinkedIn"
                  className="size-[1.125rem]"
                />
              </UnstyledButton>
            )}
          </Group>
          {clientCompany?.name && clientJobTitle && (
            <Text className="text-[--mantine-color-gray-7] text-sm">
              {clientJobTitle}, {clientCompany.name}
            </Text>
          )}
          {userIndustries.length > 0 && (
            <UserIndustries industries={userIndustries} />
          )}
          {countryCode && (
            <UserLocation city={city || ""} countryCode={countryCode} />
          )}
          {clientProfile && email && (
            <Text className="text-[--mantine-color-gray-7] text-sm">
              {email}
            </Text>
          )}
          {userSummary && props.showSummary && (
            <Text
              className={clsx(
                props.showSummary === "truncate" && "line-clamp-1",
              )}
            >
              {userSummary}
            </Text>
          )}
        </Stack>
      </Flex>
    </>
  );
}
