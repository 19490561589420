import { faThumbsUp as regular } from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import { faThumbsUp as solid } from "@awesome.me/kit-af809b8b43/icons/classic/solid";
import { UnstyledButton } from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { type LikeGroupType, useLikeMutation } from "@/hooks/use-like";

type Props = {
  count: number;
  likeGroupType: LikeGroupType;
  groupId: string;
  likedByMe?: boolean;
  verbose?: boolean;
};

export function Like({
  count,
  likeGroupType,
  groupId,
  likedByMe = false,
  verbose = false,
}: Props) {
  const { mutateAsync, isPending } = useLikeMutation({
    type: likeGroupType,
    groupId,
  });

  const formattedCount =
    count >= 10000 ? `${(count / 1000).toFixed(1)}K` : count;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        mutateAsync({ action: likedByMe ? "delete" : "post" });
      }}
    >
      <UnstyledButton
        type="submit"
        disabled={isPending}
        className={clsx(
          "flex items-center gap-1",
          likedByMe
            ? "text-[color:--mantine-color-violet-7]"
            : "text-[color:--mantine-color-gray-6]",
        )}
      >
        <FontAwesomeIcon
          className="size-5"
          title="thumbs up"
          icon={likedByMe ? solid : regular}
        />
        {!verbose
          ? formattedCount
          : count <= 0
            ? "Be the first to like this document"
            : `${formattedCount} user${
                count > 1 ? "s" : ""
              } found this helpful`}
      </UnstyledButton>
    </form>
  );
}
