import IdConfiguration = google.accounts.id.IdConfiguration;

import { useScript } from "./use-script";

interface SSOTokens {
  credentialToken?: string;
  accessToken?: string;
}

interface SSOConfigProps {
  clientID: string;
  context: IdConfiguration["context"];
}

interface InitiateSSOProps {
  onSuccess?: (tokens: SSOTokens) => void;
  onCancel?: () => void;
  onDismiss?: () => void;
}

const config: SSOConfigProps = {
  clientID: import.meta.env.VITE_GOOGLE_SSO_CLIENT_ID,
  context: "use",
};

const useGoogleSSO = () => {
  const status = useScript("https://accounts.google.com/gsi/client");

  const initiateSSO = (props: InitiateSSOProps) => {
    if (status === "ready") {
      const tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: config.clientID,
        scope:
          "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
        callback: (response) => {
          if (response.error) {
            console.error("Google SSO failed to retrieve the access token.");
            props.onCancel?.();
            return;
          }

          props.onSuccess?.({
            accessToken: response.access_token,
          });
        },
        error_callback: (_error) => {
          console.log("Google SSO pop-up window was closed.");
          props.onCancel?.();
        },
      });

      tokenClient.requestAccessToken();
    }

    if (status === "error") {
      console.error("Google SSO is not available");
      props.onCancel?.();
    }
  };

  return { initiateSSO, status };
};

export { useGoogleSSO };
