import { clsx } from "clsx/lite";
import {
  type ForwardedRef,
  type InputHTMLAttributes,
  type ReactNode,
  forwardRef,
  useId,
} from "react";

import * as styles from "./OptionButton.module.css";

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, "type"> {
  label: ReactNode;
}

const OptionButton = forwardRef(
  (
    { className, label, ...props }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const uuid = useId();
    const id = props.id || uuid;

    return (
      <div className={clsx(styles.root, className)}>
        <input
          {...props}
          id={id}
          type="radio"
          className={styles.input}
          ref={ref}
        />
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
      </div>
    );
  },
);

export { OptionButton };
