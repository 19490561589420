import { faEllipsisV } from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import { Button, Group, Menu, Stack, Text } from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generatePath, useNavigate } from "react-router-dom";

import { DocumentStatus } from "@/components/molecules/DocumentStatus/DocumentStatus";
import { paths } from "@/routes/paths";
import type { SDocument } from "types";

interface Props {
  document: SDocument;
  adminView: boolean;
  onDelete?: (documentId: string) => void;
  onMoveToDraft?: (documentId: string) => void;
  onPublish?: (documentId: string) => void;
  onDecline?: (documentId: string) => void;
  onClick?: (documentId: string, documentSlug?: string) => void;
}

const DocumentListItem = (props: Props) => {
  const navigate = useNavigate();

  // allow admin to edit documents in review, and published
  // allow author to edit documents in draft
  const isEditable =
    props.document.status === "DRAFT" ||
    ((props.document.status === "IN_REVIEW" ||
      props.document.status === "PUBLISHED") &&
      props.adminView);

  const isMenuVisible =
    props.onDelete ||
    props.onMoveToDraft ||
    props.onPublish ||
    props.onDecline ||
    isEditable;

  return (
    <Stack
      className="mr-10 cursor-pointer gap-1"
      onClick={() =>
        props.onClick?.(props.document.id, props.document.urlTitle)
      }
    >
      <Group className="-mr-10 flex-nowrap gap-1">
        <DocumentStatus document={props.document} adminView={props.adminView} />
        {isMenuVisible && (
          <Menu position="bottom-end">
            <Menu.Target>
              <Button
                variant="transparent"
                aria-label="Document actions"
                className="ml-auto h-fit text-inherit"
                data-testid={`doc-menu-${props.document.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <FontAwesomeIcon icon={faEllipsisV} />
              </Button>
            </Menu.Target>
            <Menu.Dropdown
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {isEditable && (
                <Menu.Item
                  onClick={() =>
                    navigate(
                      generatePath(paths.profile.documents.edit, {
                        documentId: props.document.id,
                      }),
                    )
                  }
                >
                  Edit document
                </Menu.Item>
              )}
              {props.onMoveToDraft && (
                <Menu.Item
                  onClick={() => props.onMoveToDraft?.(props.document.id)}
                >
                  Move to draft
                </Menu.Item>
              )}
              {props.onPublish && (
                <Menu.Item onClick={() => props.onPublish?.(props.document.id)}>
                  Publish
                </Menu.Item>
              )}
              {props.onDecline && (
                <Menu.Item
                  color="red"
                  onClick={() => props.onDecline?.(props.document.id)}
                >
                  Decline
                </Menu.Item>
              )}
              {props.onDelete && (
                <Menu.Item
                  color="red"
                  onClick={() => props.onDelete?.(props.document.id)}
                >
                  Delete document
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>
        )}
      </Group>
      <Text className="font-medium">
        {props.document.software?.name || props.document.topic?.title}
      </Text>
      {props.adminView && props.document.docType === "SOFTWARE_CHECKLIST" ? (
        <>
          <Text>
            Category: {props.document.software?.category?.category || ""}
          </Text>
          <Text>
            Sub-category: {props.document.software?.category?.subcategory || ""}
          </Text>
          <Text>
            Prompt category: {props.document.software?.category?.prompt || ""}
          </Text>
        </>
      ) : null}

      {props.adminView && props.document.docType === "IT_BEST_PRACTICE" ? (
        <>
          <Text>
            Categories: {props.document.topic?.categories?.join(",") || ""}
          </Text>
        </>
      ) : null}

      {props.adminView && (
        <Text>
          {[
            props.document.author?.firstName,
            props.document.author?.lastName,
          ].join(" ") || props.document.author?.name}
          {props.document.newJoinerDocument && (
            <>
              ,{" "}
              <span className="text-[--mantine-color-green-5] italic">
                new joiner
              </span>
            </>
          )}
        </Text>
      )}
    </Stack>
  );
};

export { DocumentListItem };
