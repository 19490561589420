import {
  Button,
  Flex,
  SimpleGrid,
  Stack,
  Text,
} from "@flpstudio/design-system";
import axios from "axios";
import { clsx } from "clsx/lite";
import { type FormEvent, useCallback, useEffect, useState } from "react";

import { OptionButton } from "@/components/atoms/OptionButton/OptionButton";
import { softwarePrompts } from "@/templates/software-prompts";
import type { SDocument, Software } from "types";
import * as styles from "./CreateDocumentSelectSoftwareForm.module.css";

interface Props {
  className?: string;
  onSubmit: (document: SDocument) => void;
  onCancel: () => void;
  /**
   * Whether the form should have sticky controls at the bottom
   */
  withStickyControls?: boolean;
}

const CreateDocumentSelectSoftwareForm = (props: Props) => {
  const [list, setList] = useState<Software[] | null>(null);
  const [showError, setShowError] = useState(false);

  const getList = useCallback(() => {
    axios
      .get<Software[]>("/document/prompts/software-checklists")
      .then((response) => {
        setList(response.data);
      });
  }, []);

  useEffect(getList, []);

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const target = event.target as typeof event.target & {
        softwareId: { value: string };
      };
      const softwareId = target.softwareId.value;
      const softwareName =
        list?.find((item) => item.id === softwareId)?.name || "";

      setShowError(!softwareId);

      if (softwareId) {
        axios
          .post<SDocument>("/documents/software-checklists", {
            softwareId,
            content: {
              // TODO: This can be a slow operation especially on mobile once the object gets larger, so improve this later
              prompts: JSON.parse(
                JSON.stringify(softwarePrompts).replaceAll(
                  "%software%",
                  softwareName,
                ),
              ),
            },
          })
          .then((response) => {
            props.onSubmit(response.data);
          });
      }
    },
    [props.onSubmit, list],
  );

  return (
    <form
      name="createDocumentSelectSoftware"
      onSubmit={onSubmit}
      className={props.className}
    >
      <Stack>
        <Flex className={styles.formContent}>
          <Text>
            The following applications align with both your expertise and our
            platform documentation needs. Please select an application, then
            write a document following our prompts.
          </Text>
          {list && list.length > 0 && (
            <SimpleGrid cols={{ lg: 2 }}>
              {list.map((item) => (
                <OptionButton
                  key={item.id}
                  label={
                    <>
                      <img
                        src={item.icon}
                        alt={item.name}
                        style={{ width: "24px", height: "24px" }}
                      />
                      <Text span lineClamp={1}>
                        {item.name}
                      </Text>
                    </>
                  }
                  name="softwareId"
                  value={item.id}
                  className={styles.option}
                />
              ))}
            </SimpleGrid>
          )}
          {list && list.length === 0 && (
            <Text className={styles.secondaryText}>
              No software is available for document creation at this time.
              Please check back later.
            </Text>
          )}
          {showError && (
            <Text className={styles.errorText}>
              Please select an option to proceed
            </Text>
          )}
        </Flex>
        <Flex
          className={clsx(
            styles.formControls,
            props.withStickyControls && styles.withStickyControls,
          )}
        >
          {list && list.length > 0 && (
            <Button type="submit">Start writing</Button>
          )}
          <Button variant="outline" onClick={props.onCancel}>
            Cancel
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export { CreateDocumentSelectSoftwareForm };
