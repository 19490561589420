import { clsx } from "clsx/lite";
import type { PropsWithChildren } from "react";

import * as styles from "./Screen.module.css";

interface Props extends PropsWithChildren {
  className?: string;
}

const Screen = (props: Props) => (
  <div className={clsx(styles.root, props.className)}>{props.children}</div>
);

export { Screen };
