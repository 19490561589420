import { Paper, Text } from "@flpstudio/design-system";

import { Logo } from "@/components/atoms/Logo/Logo";
import * as styles from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => (
  <div className={styles.screen}>
    <div className={styles.content}>
      <header className={styles.header}>
        <Logo />
      </header>
      <main className={styles.main}>
        <Paper className={styles.paper}>
          <Text component="h1" className={styles.heading}>
            Privacy Policy
          </Text>
          <Text className={styles.subText}>
            The text for the Privacy Policy goes here...
          </Text>
        </Paper>
      </main>
    </div>
  </div>
);

export { PrivacyPolicy };
