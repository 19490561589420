import { Button, Group, Modal, Text, Title } from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";

import {
  ITSetupForm,
  IT_LEVELS,
  PRODUCTIVITY_SUITES,
  isITSetupCompleted,
} from "@/components/organisms/ClientOnboarding/ITSetupForm";
import { useAuth } from "@/hooks/use-auth";
import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";

export function EditableITSetup() {
  const { user } = useAuth();
  const { isModalOpen } = useProfilePageSearchParams();
  const [opened, handlers] = useDisclosure(isModalOpen("itSetup"));

  if (!user) return null;

  return (
    <>
      <Group className="mb-4 justify-between">
        <Title order={4}>IT setup</Title>
        <Button variant="outline" onClick={handlers.open}>
          Edit
        </Button>
      </Group>
      {isITSetupCompleted(user) ? (
        <dl className="m-0">
          <dt>Comfort level with IT</dt>
          <dd className="mt-1 mb-4 ml-0 text-[color:--mantine-color-gray-6]">
            {
              IT_LEVELS.find((s) => s.value === user.clientProfile?.itLevel)
                ?.label
            }
          </dd>

          <dt>Productivity suite</dt>
          <dd className="mt-1 mb-4 ml-0 text-[color:--mantine-color-gray-6]">
            {
              PRODUCTIVITY_SUITES.find(
                (s) =>
                  s.value === user.clientProfile?.company?.productivitySuite,
              )?.label
            }
          </dd>

          <dt>How does your company currently manage IT?</dt>
          <dd className="mt-1 mb-4 ml-0 text-[color:--mantine-color-gray-6]">
            {user.clientProfile?.company?.itSummary}
          </dd>

          {user.clientProfile?.company?.lookingFor && (
            <>
              <dt>What is the primary reason you came to Guidestack?</dt>
              <dd className="mt-1 mb-4 ml-0 text-[color:--mantine-color-gray-6]">
                {user.clientProfile?.company?.lookingFor}
              </dd>
            </>
          )}
        </dl>
      ) : (
        <Text className="text-[color:--mantine-color-gray-6]">
          This information will help experts provide guidance tailored to your
          company. It will be visible only to you and any experts you directly
          book consultations with.
        </Text>
      )}
      <Modal
        opened={opened}
        onClose={() => {
          /* noop */
        }}
        title="IT setup"
      >
        <ITSetupForm
          onSubmitText="Close"
          onSubmit={handlers.close}
          withStickyControls
        />
      </Modal>
    </>
  );
}
