import axios, { type AxiosError } from "axios";
import cookie from "js-cookie";
import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";

import { randomProfileColorPicker } from "@/utils/profile-colors";
import type { SignInType, User } from "types";

const ACCESS_TOKEN_COOKIE_NAME = "sl_at";
const REFRESH_TOKEN_COOKIE_NAME = "sl_rt";

let axios401SignoutInProgress = false;
let axios401Interceptor: number | undefined = undefined;

type JWT = {
  accessToken: string;
  refreshToken: string;
};

type SignInProps = {
  /**
   * For all types of tokens
   */
  token?: string;
  /**
   * For some cases the accessToken is explicitly required.
   */
  accessToken?: string;
  signInType: SignInType;
};

type UserRole = "admin" | "customer";
type AuthContextProps = {
  signIn: (signInProps: SignInProps) => Promise<string>;
  signOut: () => Promise<string>;
  deleteUser: () => Promise<string>;
  user: User | null | undefined;
  role: UserRole;
  setRole: Dispatch<SetStateAction<UserRole>>;
  updateUser: (userProps: Partial<User>) => Promise<string>;
};

const AuthContext = createContext<AuthContextProps | null>(null);

const AuthProvider = (props: PropsWithChildren) => {
  // undefined: We don't know if there is a user yet.
  // null: There is no user.
  // User: There is a user that is authenticated.
  const [user, setUser] = useState<User | null | undefined>(undefined);
  const [role, setRole] = useState<"admin" | "customer">("customer");

  useEffect(() => {
    if (user?.isAdmin) {
      setRole("admin");
    }
  }, [user?.isAdmin]);

  const signIn = useCallback(async (signInProps: SignInProps) => {
    let ssoPath = "";

    switch (signInProps.signInType) {
      case "GOOGLE_SSO":
        ssoPath = "/auth/sso/google";
        break;
      case "MICROSOFT_SSO":
        ssoPath = "/auth/sso/microsoft";
        break;
      default:
        break;
    }

    try {
      const { data } = await axios.post<JWT>(ssoPath, {
        token: signInProps.token || "",
        accessToken: signInProps.accessToken || "",
      });

      // Set the cookies to be secure only in production.
      // Secure cookies don't work in development because the server may not be using HTTPS.
      cookie.set(ACCESS_TOKEN_COOKIE_NAME, data.accessToken, {
        expires: 1,
        sameSite: "strict",
        secure: import.meta.env.PROD,
      });
      cookie.set(REFRESH_TOKEN_COOKIE_NAME, data.refreshToken, {
        expires: 70,
        sameSite: "strict",
        secure: import.meta.env.PROD,
      });

      await getUser();

      return "Sign-in successful.";
    } catch (error) {
      // Typecast the error to AxiosError
      const errorObj = error as AxiosError<{
        message: string;
        statusCode: number;
      }>;
      throw new Error(errorObj.response?.data?.message);
    }
  }, []);

  const clearAxios401Interceptor = useCallback(() => {
    if (axios401Interceptor) {
      axios.interceptors.response.eject(axios401Interceptor);
    }
  }, []);

  const setupAxios401Interceptor = useCallback(() => {
    try {
      axios401Interceptor = axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (
            error.response.status === 401 &&
            error.response.config.url !== "/auth/logout" &&
            !axios401SignoutInProgress
          ) {
            axios401SignoutInProgress = true;
            signOut();
            axios401SignoutInProgress = false;
          }
          throw error;
        },
      );
    } catch (_err) {}
  }, []);

  const getUser = useCallback(async () => {
    axios.defaults.headers.common.Authorization = `Bearer ${cookie.get(
      ACCESS_TOKEN_COOKIE_NAME,
    )}`;

    try {
      const { data } = await axios.get<User>("/users/me");
      data.randomProfileColor = randomProfileColorPicker(data.email);
      setUser(data);
      setupAxios401Interceptor();
      return "Requesting User successful.";
    } catch (_error) {
      signOut();
      throw new Error("Requesting user failed.");
    }
  }, [setupAxios401Interceptor]);

  const updateUser = useCallback(async (userProps: Partial<User>) => {
    try {
      const { data } = await axios.patch<User>("/users/me", userProps);
      data.randomProfileColor = randomProfileColorPicker(data.email);
      setUser(data);
      return "User update successful.";
    } catch (_error) {
      throw new Error("User update failed.");
    }
  }, []);

  const signOut = useCallback(async () => {
    // There is no need to handle if the request fails because the user is logging out anyway.
    axios.post("/auth/logout");

    // Do all the cleanups
    cookie.remove(ACCESS_TOKEN_COOKIE_NAME);
    cookie.remove(REFRESH_TOKEN_COOKIE_NAME);
    axios.defaults.headers.common.Authorization = undefined;
    setUser(null);
    clearAxios401Interceptor();
    return "Sign-out successful.";
  }, [clearAxios401Interceptor]);

  const deleteUser = useCallback(async () => {
    await axios.delete("/users/me");

    // Clean up the user and tokens
    signOut();

    return "User deleted successfully.";
  }, [signOut]);

  useEffect(() => {
    if (cookie.get(REFRESH_TOKEN_COOKIE_NAME)) {
      getUser();
    } else {
      setUser(null);
    }
  }, [getUser]);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        user,
        updateUser,
        deleteUser,
        role,
        setRole,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
