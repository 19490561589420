import {
  ThemeProvider,
  theme as baseTheme,
  mergeTheme,
  rem,
} from "@flpstudio/design-system/theming";

import * as layoutStyles from "./Layout.module.css";
import * as modalStyles from "./Modal.module.css";
import * as paperStyles from "./Paper.module.css";
import * as tooltipStyles from "./Tooltip.module.css";

const theme = mergeTheme(baseTheme, {
  colors: {
    violet: [
      "#F9F4FF",
      "#F4EBFF",
      "#E9D7FE",
      "#CAB2FF",
      "#A07CEF",
      "#7F56D9",
      "#6941C6",
      "#53389E",
      "#45269C",
      "#35158D",
    ],
  },
  primaryColor: "violet",
  headings: {
    fontWeight: "600",
  },
  components: {
    // Layout
    Container: {
      classNames: {
        root: layoutStyles.container,
      },
    },
    Flex: {
      classNames: {
        root: layoutStyles.flex,
      },
    },
    // Component
    Paper: {
      classNames: paperStyles,
    },
    Drawer: {
      defaultProps: {
        position: "bottom",
        withCloseButton: false,
        size: "xl",
      },
    },
    Menu: {
      classNames: {
        dropdown: "p-0",
        item: "rounded-none p-2 first-of-type:rounded-t last-of-type:rounded-b",
      },
    },
    Modal: {
      classNames: modalStyles,
      defaultProps: {
        withCloseButton: false,
        centered: true,
        size: "lg",
      },
    },
    Tabs: {
      classNames: {
        list: "gap-[--mantine-spacing-md]",
        tab: "px-0 rounded-[--mantine-radius-default] hover:bg-transparent data-[active=true]:font-semibold",
      },
    },
    Tooltip: {
      classNames: tooltipStyles,
      defaultProps: {
        position: "bottom",
        arrowSize: 12,
        withArrow: true,
        multiline: true,
      },
    },
  },
});

export { rem, theme, ThemeProvider };
