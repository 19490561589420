import {
  SimpleGrid,
  Stack,
  Text,
  Title,
  type TitleProps,
} from "@flpstudio/design-system";
import { clsx } from "clsx/lite";
import DOMPurify from "dompurify";
import parseHtml from "html-react-parser";
import { Fragment, type ReactNode } from "react";

import { LinkPreview } from "@/components/atoms/LinkPreview/LinkPreview";
import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { DocumentStatus } from "@/components/molecules/DocumentStatus/DocumentStatus";
import { Like } from "@/components/molecules/Like/Like";
import { useDocument, usePublishedDocument } from "@/hooks/use-documents";
import type { SDocumentContentPrompt } from "types";
import { SoftwareGroup } from "../SoftwareGroup/SoftwareGroup";
import { UserProfile } from "../UserProfile/UserProfile";
import * as styles from "./Document.module.css";

type DocumentProps = {
  id?: string;
  urlSlug?: string;
  viewAsPublished?: boolean;
  className?: string;
};

export function Document(props: DocumentProps) {
  // Both `id` and `urlSlug` are optional, but at least one must be provided
  // And `urlSlug` is given preference over `id`
  const {
    data: document,
    isLoading: documentIsLoading,
    isError: documentIsError,
  } = useDocument(props.urlSlug ? undefined : props.id);
  const {
    data: publishedDocument,
    isLoading: publishedDocumentIsLoading,
    isError: publishedDocumentIsError,
  } = usePublishedDocument(props.urlSlug);

  const documentData = publishedDocument || document;
  const isLoading = publishedDocumentIsLoading || documentIsLoading;
  const isError = publishedDocumentIsError || documentIsError;

  if (isLoading) {
    return <Skeleton repeat={5} />;
  }

  if (isError || !documentData) {
    return <div className={props.className}>Error</div>;
  }

  return (
    <Stack className={clsx(styles.document, props.className)}>
      <Title order={1} className={clsx(props.viewAsPublished && "sr-only")}>
        {documentData.content.prompts[0]?.title}
      </Title>
      <DocumentStatus document={documentData} />
      {documentData.declineFeedback && (
        <Feedback message={documentData.declineFeedback} />
      )}
      {documentData.author && <UserProfile user={documentData.author} />}
      <Like
        count={documentData.likeCount}
        groupId={documentData.likeGroup}
        likeGroupType="DOCUMENT"
        likedByMe={documentData.likedByMe}
        verbose
      />
      <Stack>
        {documentData?.content?.prompts[0]?.subPrompts?.map((content) => {
          let headingOrder = 2;
          const nodes: ReactNode[] = [];

          const renderNodes = (subContent: SDocumentContentPrompt) => {
            nodes.push(
              <Fragment key={subContent.id}>
                {content.metadata?.recommendations?.value?.length ? (
                  <Stack>
                    <span className="font-semibold">Recommended tools</span>
                    {content.metadata?.recommendations.value && (
                      <SoftwareGroup
                        list={content.metadata?.recommendations.value}
                      />
                    )}
                  </Stack>
                ) : null}
                {(!Object.hasOwn(subContent, "value") || subContent.value) && (
                  <Title
                    order={headingOrder as TitleProps["order"]}
                    className={clsx(
                      documentData.docType === "IT_BEST_PRACTICE" && "sr-only",
                    )}
                  >
                    {subContent.title}
                  </Title>
                )}
                {subContent.value && (
                  <div className={styles.richText}>
                    {parseHtml(DOMPurify.sanitize(subContent.value))}
                  </div>
                )}
                {subContent.metadata?.relevantArticles?.value?.length ? (
                  <Stack>
                    <Title
                      order={(headingOrder + 1) as TitleProps["order"]}
                      fz="md"
                    >
                      Relevant articles for further learning
                    </Title>
                    <SimpleGrid cols={{ base: 1, lg: 3 }} className="gap-2">
                      {subContent.metadata?.relevantArticles.value.map(
                        (article) => (
                          <LinkPreview
                            key={article.url}
                            url={article.url}
                            title={article.title}
                            favicon={article.favicon}
                            description={article.description}
                          />
                        ),
                      )}
                    </SimpleGrid>
                  </Stack>
                ) : null}
              </Fragment>,
            );

            if (subContent.subPrompts) {
              headingOrder++;
              for (const subPrompts of subContent.subPrompts) {
                renderNodes(subPrompts);
              }
            }
          };

          renderNodes(content);

          return nodes;
        })}
      </Stack>
    </Stack>
  );
}

function Feedback({ message }: { message: string }) {
  return (
    <Stack p="md" className="rounded bg-[--mantine-color-orange-0]">
      <Title order={5}>Feedback from Guidestack admins</Title>
      <Text>{message}</Text>
    </Stack>
  );
}
