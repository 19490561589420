import type { User } from "types";

export function isExpertProfileCompleted(user?: Partial<User> | null) {
  if (!user) {
    return false;
  }

  if (!user.expertProfile) {
    return false;
  }

  return Boolean(
    user.firstName &&
      user.lastName &&
      user.countryCode &&
      user.expertProfile.summary &&
      user.expertProfile.summary.length >= 20 &&
      user.expertProfile.linkedinUrl,
  );
}
