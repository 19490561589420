import type { SDocumentContentPrompt } from "types";

const softwarePrompts: SDocumentContentPrompt[] = [
  {
    id: "a8b7d1c9-f7e0-4587-abdc-e765090102fc",
    title: "%software%",
    subPrompts: [
      {
        id: "740d4ed1-b400-4c9d-aa75-4165a4cd155e",
        title: "When and why to choose %software%",
        subPrompts: [
          {
            id: "d5976433-be0d-4fa0-9bbe-349160350414",
            title: "Best use cases for %software%",
            description:
              "Please explain when and why you recommend a company selects this software, being as specific as possible about the following factors that may influence decision making: specific use cases, employee count, client base size, industry, IT environment, budget, etc.",
            value: "",
            required: true,
            metadata: {
              relevantArticles: {
                value: [],
                required: false,
              },
            },
          },
          {
            id: "adf517d6-a751-42b0-b87c-bddaa89853ce",
            title: "Scenarios in which other software might be more suitable",
            description:
              "Please give a few examples of use cases or scenarios in which you would recommend using other tools instead of this application.",
            value: "",
            required: true,
            metadata: {
              relevantArticles: {
                value: [],
                required: false,
              },
            },
          },
        ],
      },
      {
        id: "80a63687-ef28-403b-9703-d52d340963af",
        title: "How to set up and manage %software% with other tools",
        subPrompts: [
          {
            id: "d55e590a-b02c-4469-b5cf-490b97aade11",
            title: "How to get started with %software%",
            description: "How should a company get started with this software?",
            value: "",
            required: true,
            metadata: {
              relevantArticles: {
                value: [],
                required: false,
              },
            },
          },
          {
            id: "b98c19c9-ff44-48eb-84fc-c91cc014ba64",
            title: "Migrating from similar tools",
            description:
              "What are common platforms companies might migrate from to this new tool? What factors and best practices should they consider when migrating?",
            value: "",
            required: false,
            metadata: {
              relevantArticles: {
                value: [],
                required: false,
              },
            },
          },
          {
            id: "d060f8e0-be6e-4cc7-84b1-687d7bcb983e",
            title: "Compatible tools",
            description:
              "Which complementary software applications are most compatible with this tool, and what should companies consider when setting up or integrating these additional tools?",
            value: "",
            required: false,
            metadata: {
              relevantArticles: {
                value: [],
                required: false,
              },
            },
          },
          {
            id: "e62683ef-5736-4094-8d12-b1bfe234414e",
            title: "Less compatible tools",
            description:
              "Which software applications are less compatible with this tool, and what should companies consider if they are using these less compatible applications?",
            value: "",
            required: false,
            metadata: {
              relevantArticles: {
                value: [],
                required: false,
              },
            },
          },
        ],
      },
    ],
  },
];

export { softwarePrompts };
