import { Container, Divider, Paper, Title } from "@flpstudio/design-system";
import { EditableCompanyInfo } from "./EditableCompanyInfo";
import { EditableITSetup } from "./EditableITSetup";
import { EditableProfile } from "./EditableProfile";
import { SoftwareInUse } from "./SoftwareInUse";

export function Profile() {
  return (
    <Container component="main">
      <Title order={3}>My IT profile</Title>
      <Paper className="-mx-6 mt-4 lg:mx-0">
        <EditableProfile />
        <Divider className="-mx-6 my-6" />
        <SoftwareInUse />
        <Divider className="-mx-6 my-6" />
        <EditableCompanyInfo />
        <Divider className="-mx-6 my-6" />
        <EditableITSetup />
      </Paper>
    </Container>
  );
}
