import { Anchor, Flex, Stack, Text } from "@flpstudio/design-system";
import { clsx } from "clsx/lite";
import type { SDocumentPromptMetadata } from "types";

type Props = SDocumentPromptMetadata & {
  className?: string;
};

const LinkPreview = (props: Props) => (
  <Anchor
    href={props.url}
    target="_blank"
    rel="noreferrer noopener nofollow"
    className={clsx(
      "inline-block rounded-[--mantine-radius-default] border border-[--mantine-color-gray-4] border-solid p-2 text-inherit no-underline",
      props.className,
    )}
  >
    <Stack className="gap-0">
      <Flex className="gap-2">
        {props.favicon && (
          <img src={props.favicon} alt={props.title} className="size-5" />
        )}
        <span className="line-clamp-1 font-semibold text-sm/normal">
          {props.title}
        </span>
      </Flex>
      <Text
        span
        className="line-clamp-1 text-[--mantine-color-gray-6] text-xs/normal"
      >
        {props.description}
      </Text>
    </Stack>
  </Anchor>
);

export { LinkPreview };
