import {
  Button,
  Checkbox,
  Flex,
  SimpleGrid,
  Stack,
  Text,
} from "@flpstudio/design-system";
import { zodResolver } from "@hookform/resolvers/zod";
import { type SubmitHandler, useForm } from "react-hook-form";
import { z as validator } from "zod";

import { useAuth } from "@/hooks/use-auth";
import { CLIENT_MATURITY } from "@/utils/client-maturity";
import { CLIENT_SIZE } from "@/utils/client-size";
import { clsx } from "clsx/lite";
import type { ClientMaturity, ClientSize } from "types";
import * as styles from "./ExpertPreferencesForm.module.css";

interface Props {
  className?: string;
  onBack?: () => void;
  onSubmit: () => void;
  onSubmitText: "Close" | "Submit";
  /**
   * Whether the form should have sticky controls at the bottom
   */
  withStickyControls?: boolean;
}

const schema = validator.object({
  companySize: validator
    .array(validator.string())
    .min(1, "Please select at least one option to proceed"),
  clientMaturity: validator
    .array(validator.string())
    .min(1, "Please select at least one option to proceed"),
});

type FormSchema = validator.infer<typeof schema>;

const ExpertPreferencesForm = (props: Props) => {
  const { user, updateUser } = useAuth();
  const { formState, getValues, register, handleSubmit } = useForm<FormSchema>({
    defaultValues: {
      companySize: user?.expertProfile?.preferClientSizes || [],
      clientMaturity: user?.expertProfile?.preferClientMaturities || [],
    },
    reValidateMode: "onBlur",
    resolver: zodResolver(schema),
  });

  const saveData = async () => {
    try {
      await updateUser({
        expertProfile: {
          preferClientSizes: getValues("companySize") as ClientSize[],
          preferClientMaturities: getValues(
            "clientMaturity",
          ) as ClientMaturity[],
        },
      });
    } catch (_error) {}
  };

  const onSubmit: SubmitHandler<FormSchema> = async () => {
    try {
      await saveData();
      props.onSubmit();
    } catch (_error) {}
  };

  return (
    <form
      name="expertPreferences"
      onSubmit={handleSubmit(onSubmit)}
      className={props.className}
    >
      <Stack>
        <Flex className={styles.formContent}>
          <Text className={styles.question}>
            What size of client company are you most comfortable advising?
            (Select all that apply)
          </Text>
          <SimpleGrid cols={{ base: 2, lg: 4 }}>
            {CLIENT_SIZE.map((size) => (
              <Checkbox
                key={size.value}
                label={size.label}
                value={size.value}
                {...register("companySize", {
                  // Immediately save the data when the option is changed
                  onChange: saveData,
                })}
              />
            ))}
          </SimpleGrid>
          {formState.errors.companySize && (
            <Text c="red">{formState.errors.companySize.message}</Text>
          )}
          <Text className={styles.question}>
            What stage of IT maturity are you able to support in client
            organizations? (Select all that apply)
          </Text>
          <Stack>
            {CLIENT_MATURITY.map((clientMaturity) => (
              <Checkbox
                key={clientMaturity.value}
                label={clientMaturity.label}
                value={clientMaturity.value}
                {...register("clientMaturity", {
                  // Immediately save the data when the option is changed
                  onChange: saveData,
                })}
              />
            ))}
          </Stack>
          {formState.errors.clientMaturity && (
            <Text c="red">{formState.errors.clientMaturity.message}</Text>
          )}
          <Flex
            className={clsx(
              styles.formControls,
              props.withStickyControls && styles.withStickyControls,
            )}
          >
            <Button
              type="submit"
              variant={props.onSubmitText === "Close" ? "outline" : ""}
            >
              {props.onSubmitText}
            </Button>
            {props.onBack && (
              <Button
                variant="transparent"
                onClick={props.onBack}
                className={styles.backButton}
              >
                Back
              </Button>
            )}
          </Flex>
        </Flex>
      </Stack>
    </form>
  );
};

export { ExpertPreferencesForm };
