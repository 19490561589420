import { Badge, Group, Text } from "@flpstudio/design-system";
import { clsx } from "clsx/lite";

import { formatDate } from "@/utils/format-date";
import type { SDocument } from "types";

type Props = {
  document: SDocument;
  adminView?: boolean;
  className?: string;
};

function getStatus(document: SDocument, adminView?: boolean) {
  if (document.publishedAt) {
    return {
      timeLabelPrefix: "Published",
      timeField: formatDate(new Date(document.publishedAt)),
    };
  }

  switch (document.status) {
    case "DRAFT":
      return {
        color: "gray",
        label: "Draft",
        timeLabelPrefix: "Last edited",
        timeField: document.editedAt
          ? formatDate(new Date(document.editedAt))
          : "",
      };
    case "IN_REVIEW":
      return {
        color: "orange",
        label: "Under review",
        timeLabelPrefix: (() => {
          if (!adminView || !document?.editedBy) {
            return "Submitted";
          }

          if (document.editedAt && document.submittedAt) {
            return new Date(document.editedAt) > new Date(document.submittedAt)
              ? `Edited by ${
                  [
                    document.editedBy.firstName,
                    document.editedBy.lastName,
                  ].join(" ") || document.editedBy?.name
                }`
              : "Submitted";
          }

          return "";
        })(),
        timeField: (() => {
          if (!adminView || !document?.editedBy) {
            return document.submittedAt
              ? formatDate(new Date(document.submittedAt))
              : "";
          }

          if (document.editedAt && document.submittedAt) {
            return formatDate(
              new Date(
                new Date(document.editedAt) > new Date(document.submittedAt)
                  ? document.editedAt
                  : document.submittedAt,
              ),
            );
          }

          return "";
        })(),
      };
    case "PUBLISHED":
      return {
        color: "green",
        label: "Published",
        timeLabelPrefix: "Published",
        timeField: document.reviewedAt
          ? formatDate(new Date(document.reviewedAt))
          : "",
      };
    default:
      return {
        color: "gray",
        label: "Declined",
        timeLabelPrefix: "Declined",
        timeField: document.reviewedAt
          ? formatDate(new Date(document.reviewedAt))
          : "",
      };
  }
}

function DocumentStatus({ document, adminView, className }: Props) {
  const status = getStatus(document, adminView);

  return (
    <Group className={clsx("flex-nowrap gap-2", className)}>
      {/* If the document as `publishedAt` field, Badge is not shown */}
      {!document.publishedAt && (
        <Badge variant="light" color={status.color} className="shrink-0">
          {status.label}
        </Badge>
      )}
      <Text className="line-clamp-1 text-[--mantine-color-gray-6] text-sm/normal">
        {status.timeLabelPrefix} {status.timeField}
      </Text>
    </Group>
  );
}

export { DocumentStatus };
