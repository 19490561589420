import type { ClientMaturity } from "types";

const CLIENT_MATURITY: { label: string; value: ClientMaturity }[] = [
  {
    label: "I can set up the basics from zero",
    value: "ZERO",
  },
  {
    label:
      "I can improve IT environments and processes for companies with some systems and tools already in place",
    value: "BASIC",
  },
  {
    label:
      "I can help mature companies with streamlining their IT practices and budgets",
    value: "MATURE",
  },
];

export { CLIENT_MATURITY };
