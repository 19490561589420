import { useEffect, useRef, useState } from "react";

type Status = "loading" | "ready" | "error";

const useScript = (src: string) => {
  const [status, setStatus] = useState<Status>("loading");
  const scriptRef = useRef<HTMLScriptElement>();

  useEffect(() => {
    scriptRef.current = document.createElement("script");
    scriptRef.current.src = src;
    scriptRef.current.async = true;

    const onLoad = () => {
      setStatus("ready");
    };

    const onError = () => {
      setStatus("error");
    };

    scriptRef.current.addEventListener("load", onLoad);
    scriptRef.current.addEventListener("error", onError);

    document.body.appendChild(scriptRef.current);

    return () => {
      if (scriptRef.current) {
        scriptRef.current.removeEventListener("load", onLoad);
        scriptRef.current.removeEventListener("error", onError);
        document.body.removeChild(scriptRef.current);
      }
      scriptRef.current = undefined;
    };
  }, [src]);

  return status;
};

export { useScript };
