import { faChevronsRight } from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import { Button, Group, Stack } from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDisclosure } from "@mantine/hooks";
import { useEffect } from "react";

import { useExpertFullProfileSearchParams } from "@/hooks/use-page-search-params";
import { Drawer } from "../../molecules/Drawer/Drawer";
import { Booking } from "../../organisms/Booking/Booking";
import { ExpertProfile } from "../../organisms/ExpertProfile/ExpertProfile";

export function ViewExpertProfileDialog() {
  const [opened, handlers] = useDisclosure();
  const { getExpertIdentifier, clearExpertIdentifier } =
    useExpertFullProfileSearchParams();

  const expertIdentifier = getExpertIdentifier();

  useEffect(() => {
    if (expertIdentifier) {
      handlers.open();
    }

    return () => handlers.close();
  }, [expertIdentifier, handlers]);

  return (
    <Drawer opened={opened} onClose={clearExpertIdentifier}>
      <Stack className="mt-6">
        <Group>
          <Button
            variant="outline"
            aria-label="Close preview"
            className="h-fit border-none p-1 text-[--mantine-color-gray-7]"
            onClick={clearExpertIdentifier}
          >
            <FontAwesomeIcon icon={faChevronsRight} />
          </Button>
          <Booking expertIdentifier={expertIdentifier} />
        </Group>
        <ExpertProfile identifier={expertIdentifier} />
      </Stack>
    </Drawer>
  );
}
