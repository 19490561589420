import {
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Tabs,
} from "@flpstudio/design-system";
import { clsx } from "clsx/lite";
import { useLocation, useMatch, useNavigate } from "react-router-dom";

import { CreateDocumentForm } from "@/components/organisms/CreateDocumentForm/CreateDocumentForm";
import { useAuth } from "@/hooks/use-auth";
import { useScreenSize } from "@/hooks/use-screen-size";
import { paths } from "@/routes/paths";
import { ExpertDocuments } from "./ExpertDocuments";
import { ExpertIndustry } from "./ExpertIndustry";
import { ExpertPreferences } from "./ExpertPreferences";
import { ExpertProfile } from "./ExpertProfile";
import { ExpertSoftware } from "./ExpertSoftware";
import * as styles from "./Profile.module.css";
import { Profile as ClientProfile } from "./client/Profile";

const Profile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLg } = useScreenSize();
  const { user } = useAuth();
  const isDocumentTabActive = useMatch(`${paths.profile.documents.root}/*`);

  if (user?.clientProfile) {
    return <ClientProfile />;
  }

  if (user?.expertProfile) {
    return (
      <>
        <Container component="main">
          <Grid>
            <Grid.Col
              span={{ lg: location.pathname === paths.profile.root ? 12 : 8 }}
            >
              <Paper className={clsx(styles.paper, styles.mainContent)}>
                <Tabs
                  component="nav"
                  withUnderline={false}
                  keepMounted={false}
                  value={
                    isDocumentTabActive
                      ? paths.profile.documents.root
                      : location.pathname
                  }
                  onChange={(pathname) => navigate(pathname as string)}
                >
                  <Tabs.List>
                    <Tabs.Tab value={paths.profile.root}>IT profile</Tabs.Tab>
                    <Tabs.Tab value={paths.profile.documents.root}>
                      Documents
                    </Tabs.Tab>
                  </Tabs.List>
                  <Divider className="-mx-6 mt-2 mb-6" />
                  <Tabs.Panel value={paths.profile.root}>
                    <ExpertProfile />
                    <Divider className="-mx-6 my-6" />
                    <ExpertSoftware />
                    <Divider className="-mx-6 my-6" />
                    <ExpertIndustry />
                    <Divider className="-mx-6 my-6" />
                    <ExpertPreferences />
                  </Tabs.Panel>
                  <Tabs.Panel value={paths.profile.documents.root}>
                    <Stack>
                      {isDocumentTabActive && !isLg && <CreateDocumentForm />}
                      <ExpertDocuments />
                    </Stack>
                  </Tabs.Panel>
                </Tabs>
              </Paper>
            </Grid.Col>
            {isDocumentTabActive && isLg && (
              <Grid.Col span={{ lg: 4 }}>
                <Paper component="aside" className={styles.paper}>
                  <CreateDocumentForm />
                </Paper>
              </Grid.Col>
            )}
          </Grid>
        </Container>
      </>
    );
  }

  return null;
};

export { Profile };
