import { Button, Flex, Modal, Stack, Text } from "@flpstudio/design-system";

import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { useBookingList } from "@/hooks/use-booking";

type UnpaidBookingProps = {
  invoiceLink: string;
  onCancel: () => void;
};

const UnpaidBooking = (props: UnpaidBookingProps) => {
  const { data, isLoading, isError } = useBookingList({
    enabled: true,
  });

  const unpaidBooking = data?.find(
    (booking) => booking.status === "COMPLETED_UNPAID",
  );

  return (
    <Stack>
      {isLoading && <Skeleton />}
      {unpaidBooking && (
        <Stack className="rounded border border-[--mantine-color-gray-3] border-solid p-6">
          <Text className="font-semibold">
            Session with {unpaidBooking.expert.name}
          </Text>
          <Text>
            Amount: {unpaidBooking.price.currency} {unpaidBooking.price.amount}
          </Text>
        </Stack>
      )}
      {isError && (
        <Text className="text-[--mantine-color-gray-6]">
          Failed to load unpaid session.
        </Text>
      )}
      <Flex className="flex-col lg:flex-row-reverse">
        <Button
          component="a"
          href={props.invoiceLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={props.onCancel}
        >
          Pay now
        </Button>
        <Button variant="outline" onClick={props.onCancel}>
          Cancel
        </Button>
      </Flex>
    </Stack>
  );
};

type DialogProps = UnpaidBookingProps & {
  opened: boolean;
};

export const UnpaidBookingDialog = (props: DialogProps) => {
  return (
    <Modal
      title="Please pay for your recently completed session"
      opened={props.opened}
      onClose={props.onCancel}
    >
      <UnpaidBooking {...props} />
    </Modal>
  );
};
