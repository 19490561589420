import axios from "axios";

export type BlobInfo = {
  id: () => string;
  name: () => string;
  filename: () => string;
  blob: () => Blob;
  base64: () => string;
  blobUri: () => string;
  uri: () => string | undefined;
};
export type ProgressFn = (percent: number) => void;
type UploadImageUrl = string;
export type PresignedPostResponse = {
  result: { size96: string; original: string };
  url: string;
  fields: Record<string, string>;
};

const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64 = reader.result as string;
      resolve(base64);
    };
    reader.onerror = (e) => reject(e);
    reader.readAsDataURL(blob);
  });
};

const PreSignedImageUploadHandler = async (
  blob: BlobInfo,
  progressFn: ProgressFn,
): Promise<UploadImageUrl> => {
  try {
    const { data } = await axios.post<PresignedPostResponse>(
      "/media/upload/presigned-post",
      {
        filename: blob.filename(),
      },
    );

    const formData = new FormData();
    for (const key in data.fields) {
      const val = data.fields[key];
      if (val) {
        formData.append(key, val);
      }
    }

    formData.append("file", blob.blob());
    await axios.post(data.url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      transformRequest: (data, headers) => {
        headers.Authorization = undefined;
        return data;
      },
      onUploadProgress: (e) => {
        if (!e.total) return;
        const val = (e.loaded / e.total) * 100;
        progressFn(val);
      },
      // we should remove credentials from this request, because the request is sending to cross origin sites
      // requests with "*" in the 'Access-Control-Allow-Origin' header can't include credentials
      withCredentials: false,
    });

    return data.result.original;
  } catch (_e) {
    // TODO: capture the error in APM service
    return blobToBase64(blob.blob());
  }
};

export { PreSignedImageUploadHandler };
