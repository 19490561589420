import { Button, Flex, Modal, Stack, Text } from "@flpstudio/design-system";

import { useAuth } from "@/hooks/use-auth";
import { useDocumentMoveToDraft } from "@/hooks/use-document-mutation";

type Props = {
  id: string;
  opened: boolean;
  onMoveToDraft: () => void;
  onCancel: () => void;
  onClose: () => void;
};

export function MoveToDraftDocumentDialog(props: Props) {
  const { role } = useAuth();
  const { mutateAsync, isPending, isError } = useDocumentMoveToDraft({
    isAdminView: role === "admin",
  });

  return (
    <Modal opened={props.opened} onClose={props.onClose} title="Move to draft?">
      <Stack>
        <Text>
          If moved to draft, this document will be taken out of the admin review
          process. You can resubmit the document for review at any time.
        </Text>
        {isError && (
          <Text className="text-[--mantine-color-error]">
            Failed to move document to draft.
          </Text>
        )}
        <Flex className="flex-col lg:flex-row-reverse">
          <Button
            variant="outline"
            loading={isPending}
            disabled={!props.opened}
            onClick={async () => {
              await mutateAsync({ documentId: props.id });
              props.onMoveToDraft();
            }}
          >
            Move to draft
          </Button>
          <Button
            variant="outline"
            disabled={isPending || !props.opened}
            onClick={props.onCancel}
          >
            Cancel
          </Button>
        </Flex>
      </Stack>
    </Modal>
  );
}
