import { Drawer as DSDrawer, type DrawerProps } from "@flpstudio/design-system";

import { useScreenSize } from "@/hooks/use-screen-size";

interface Props extends Omit<DrawerProps, "position" | "size"> {}

const Drawer = (props: Props) => {
  const { isLg } = useScreenSize();

  return (
    <DSDrawer
      {...props}
      position={isLg ? "right" : "bottom"}
      classNames={{
        body: "p-6 pt-0 flex-1",
        header: "bg-[--mantine-color-white] p-6",
        overlay: "bg-transparent",
        content: "flex flex-col shadow-drawer p-0",
        title: "text-title",
      }}
    />
  );
};

export { Drawer };
