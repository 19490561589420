import type { SDocumentContentPrompt } from "types";
import { z as validator } from "zod";

import { isValidUrl } from "@/utils/url";

const promptPartialSchema = validator.object({
  id: validator.string(),
  title: validator.string(),
  description: validator.string().optional(),
  value: validator.string().trim().optional(),
  required: validator.boolean().optional(),
  metadata: validator
    .object({
      relevantArticles: validator
        .object({
          value: validator.array(
            validator.object({
              url: validator.string().trim(),
              title: validator.string().trim(),
              favicon: validator.string().trim(),
              description: validator.string().trim(),
            }),
          ),
          required: validator.boolean().optional(),
        })
        .optional(),
      recommendations: validator
        .object({
          value: validator.array(validator.any()),
          required: validator.boolean().optional(),
        })
        .optional(),
    })
    .optional(),
  // subPrompts: [] arrays of itself
});

// Prompt full schema (with subPrompts)
const promptFullSchema: validator.ZodType<SDocumentContentPrompt> =
  promptPartialSchema
    .extend({
      subPrompts: validator.lazy(() => promptFullSchema.array()).optional(),
    })
    .superRefine((schema, context) => {
      /**
       * This superRefine makes `schema.value` a mandatory field if:
       * 1. `schema.required` does not exist, or it exists and is set to `true`.
       * 2. `schema.metadata.relevantArticles` exists and has at least one item.
       */
      if (Object.hasOwn(schema, "value")) {
        // Validate the `value` field if it exists
        if (
          // If `schema.required` does not exist, or it exists and is set to `true`,
          ((!Object.hasOwn(schema, "required") || schema.required) &&
            !schema.value) ||
          // If `schema.metadata.relevantArticles` exists and has at least one item,
          (Object.hasOwn(schema, "metadata") &&
            schema.metadata?.relevantArticles?.value.length &&
            !schema.value)
        ) {
          context.addIssue({
            code: validator.ZodIssueCode.too_small,
            minimum: 1,
            type: "string",
            message: "Please add content",
            path: ["value"],
            inclusive: true,
          });
        }
      }
    })
    .superRefine((schema, context) => {
      /**
       * This superRefine makes `schema.metadata.relevantArticles.value` a mandatory field if:
       * 1. `schema.required` does not exist, or it exists and is set to `true`.
       */
      const relevantArticles = schema.metadata?.relevantArticles;

      if (relevantArticles) {
        if (
          // If `relevantArticles.required` does not exist, or it exists and is set to `true`,
          (!Object.hasOwn(relevantArticles, "required") ||
            relevantArticles.required) &&
          !relevantArticles.value.length
        ) {
          context.addIssue({
            code: validator.ZodIssueCode.too_small,
            minimum: 1,
            type: "array",
            message: "Please add valid links",
            path: ["metadata", "relevantArticles", "value"],
            inclusive: true,
          });
        }
      }
    })
    .superRefine((schema, context) => {
      if (
        Object.hasOwn(schema, "metadata") &&
        schema.metadata?.relevantArticles?.value.length
      ) {
        if (
          schema.metadata?.relevantArticles.value.some(
            (item) => !isValidUrl(item.url),
          )
        ) {
          context.addIssue({
            code: validator.ZodIssueCode.custom,
            message: "Please add valid links",
            path: ["metadata", "relevantArticles", "value"],
          });
        }
      }
    });

/**
 * The full schema would look like this:
 * const prompt = {
 *   title: "%software%",
 *   description: "",
 *   value: "",
 *   metadata: {
 *     relevantArticles: [],
 *     recommendations: [],
 *   },
 *   subPrompts: [prompt, prompt, ...], // Prompts can be nested
 * };
 */
type FormSchema = validator.infer<typeof promptFullSchema>;

export { promptFullSchema, type FormSchema };
