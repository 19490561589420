import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import type { Industry } from "types";

export function useIndustries() {
  return useQuery({
    queryKey: ["industries"],
    queryFn: async () => {
      const { data } = await axios.get<Industry[]>("/industries");
      return data;
    },
  });
}
