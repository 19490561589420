import { Button, Flex, Modal, Stack, Text } from "@flpstudio/design-system";

import { useAuth } from "@/hooks/use-auth";
import { useDocumentDeletion } from "@/hooks/use-document-mutation";

type Props = {
  id: string;
  opened: boolean;
  onDelete: () => void;
  onCancel: () => void;
  onClose: () => void;
};

export function DeleteDocumentDialog(props: Props) {
  const { role } = useAuth();
  const { mutateAsync, isPending, isError } = useDocumentDeletion({
    isAdminView: role === "admin",
  });

  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      title="Delete document?"
    >
      <Stack>
        <Text>Are you sure you want to delete this document?</Text>
        {isError && (
          <Text className="text-[--mantine-color-error]">
            Failed to delete document.
          </Text>
        )}
        <Flex className="flex-col lg:flex-row-reverse">
          <Button
            variant="outline"
            color="red"
            loading={isPending}
            disabled={!props.opened}
            onClick={async () => {
              await mutateAsync({ documentId: props.id });
              props.onDelete();
            }}
          >
            Delete document
          </Button>
          <Button
            variant="outline"
            disabled={isPending || !props.opened}
            onClick={props.onCancel}
          >
            Cancel
          </Button>
        </Flex>
      </Stack>
    </Modal>
  );
}
