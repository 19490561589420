import { Paper, Text } from "@flpstudio/design-system";

import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";
import { StepItem } from "./CalloutStepItem";

type NextStepCalloutProps = {
  hasSoftware: boolean;
  isCompanyInfoDone: boolean;
  isProfileSetupDone: boolean;
  isITSetupDone: boolean;
};

export function ClientNextStepCallout({
  hasSoftware,
  isProfileSetupDone,
  isCompanyInfoDone,
  isITSetupDone,
}: NextStepCalloutProps) {
  const { generateModalOpenLink } = useProfilePageSearchParams();

  return (
    <Paper className="flex flex-col p-4 lg:p-6">
      <Text className="font-semibold text-xl">Next steps</Text>
      <ul className="m-0 mt-4 flex list-none flex-col gap-4 p-0">
        <StepItem
          description="Complete profile setup"
          completed={isProfileSetupDone}
          link={generateModalOpenLink("myProfile")}
        />
        <StepItem
          description="Provide info about your software usage"
          completed={hasSoftware}
          link={generateModalOpenLink("software")}
        />
        <StepItem
          description="Complete company info"
          completed={isCompanyInfoDone}
          link={generateModalOpenLink("companyInfo")}
        />
        <StepItem
          description="Complete company IT setup"
          completed={isITSetupDone}
          link={generateModalOpenLink("itSetup")}
        />
      </ul>
    </Paper>
  );
}
