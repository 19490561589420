import { paths } from "@/routes/paths";
import { useSearchParams } from "react-router-dom";

type ProfilePageModalName =
  | "myProfile"
  | "software"
  | "industry"
  | "itSetup"
  | "companyInfo";

export function useProfilePageSearchParams() {
  const [searchParams] = useSearchParams();

  const isModalOpen = (modalName: ProfilePageModalName) => {
    const ret = searchParams.get("profileModal") === modalName;

    return ret;
  };

  const generateModalOpenLink = (modalName: ProfilePageModalName) => {
    const res = `${paths.profile.root}?${new URLSearchParams({
      profileModal: modalName,
    }).toString()}`;

    return res;
  };

  return { isModalOpen, generateModalOpenLink };
}

export function useExpertFullProfileSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const expertProfileParam = "expertProfile";

  const getExpertIdentifier = () => {
    return searchParams.get(expertProfileParam) || "";
  };

  const setExpertIdentifier = (identifier: string) => {
    searchParams.set(expertProfileParam, identifier);
    setSearchParams(searchParams);
  };

  const clearExpertIdentifier = () => {
    searchParams.delete(expertProfileParam);
    setSearchParams(searchParams);
  };

  return {
    getExpertIdentifier,
    setExpertIdentifier,
    clearExpertIdentifier,
  };
}

export function useBookingSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const bookSessionParam = "bookSession";
  const bookSessionValue = "start";

  const generateStartBookingLink = (url: string) => {
    const newUrl = new URL(url);
    newUrl.searchParams.set(bookSessionParam, bookSessionValue);
    return newUrl.toString();
  };

  const clearStartBooking = () => {
    searchParams.delete(bookSessionParam);
    setSearchParams(searchParams);
  };

  const isStartBooking =
    searchParams.get(bookSessionParam) === bookSessionValue;

  return {
    generateStartBookingLink,
    clearStartBooking,
    isStartBooking,
  };
}
