import { Checkbox } from "@flpstudio/design-system";
import { type CheckboxFactory, factory } from "@mantine/core";

import * as styles from "./CardCheckbox.module.css";

const CardCheckbox = factory<CheckboxFactory>((props, ref) => (
  <Checkbox classNames={styles} {...props} size="sm" ref={ref} />
));

export { CardCheckbox };
