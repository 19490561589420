import {
  Button,
  Flex,
  NativeSelect,
  SimpleGrid,
  Stack,
  Text,
  TextArea,
  TextInput,
} from "@flpstudio/design-system";
import { zodResolver } from "@hookform/resolvers/zod";
import { clsx } from "clsx/lite";
import { type SubmitHandler, useForm } from "react-hook-form";
import { z as validator } from "zod";

import { useAuth } from "@/hooks/use-auth";
import { useAutoTriggerAction } from "@/hooks/use-auto-trigger-action";
import { countryList } from "@/utils/country-list";
import { UserPhotoForm } from "../UserPhotoForm/UserPhotoForm";
import * as styles from "./ExpertProfileForm.module.css";

interface Props {
  className?: string;
  onBack?: () => void;
  onSubmit: () => void;
  onSubmitText: "Close" | "Next";
  /**
   * Whether the form should have sticky controls at the bottom
   */
  withStickyControls?: boolean;
}

const NAME_MAX_LENGTH = 50;
const CITY_MAX_LENGTH = 100;
const SUMMARY_MIN_LENGTH = 20;
const SUMMARY_MAX_LENGTH = 600;

const schema = validator.object({
  firstName: validator
    .string()
    .trim()
    .min(1, "Please complete this field to proceed")
    .max(
      NAME_MAX_LENGTH,
      `Name should be ${NAME_MAX_LENGTH} characters or less`,
    ),
  lastName: validator
    .string()
    .trim()
    .min(1, "Please complete this field to proceed")
    .max(
      NAME_MAX_LENGTH,
      `Name should be ${NAME_MAX_LENGTH} characters or less`,
    ),
  country: validator.string().min(1, "Please select an option to proceed"),
  city: validator
    .string()
    .trim()
    .max(
      CITY_MAX_LENGTH,
      `City should be ${CITY_MAX_LENGTH} characters or less`,
    ),
  linkedIn: validator
    .string()
    .trim()
    .min(1, "Please complete this field to proceed")
    .regex(
      /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/.+/i,
      "Please provide a valid LinkedIn profile URL",
    )
    .url("Please provide a LinkedIn URL."),
  summary: validator
    .string()
    .trim()
    .min(1, "Please complete this field to proceed")
    .min(
      SUMMARY_MIN_LENGTH,
      `Summary should be more than ${SUMMARY_MIN_LENGTH} characters`,
    )
    .max(
      SUMMARY_MAX_LENGTH,
      `Summary should be ${SUMMARY_MAX_LENGTH} characters or less`,
    ),
});

type FormSchema = validator.infer<typeof schema>;

const ExpertProfileForm = (props: Props) => {
  const { user, updateUser } = useAuth();
  const { formState, getValues, register, handleSubmit, watch } =
    useForm<FormSchema>({
      resolver: zodResolver(schema),
      reValidateMode: "onBlur",
      defaultValues: {
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        country: user?.countryCode || "",
        city: user?.city || "",
        linkedIn: user?.expertProfile?.linkedinUrl || "",
        summary: user?.expertProfile?.summary || "",
      },
    });
  const summaryLength = watch("summary").length;

  const saveData = async () => {
    try {
      await updateUser({
        firstName: getValues("firstName"),
        lastName: getValues("lastName"),
        countryCode: getValues("country"),
        city: getValues("city"),
        expertProfile: {
          linkedinUrl: getValues("linkedIn"),
          summary: getValues("summary"),
        },
      });
    } catch (_error) {}
  };

  const onSubmit: SubmitHandler<FormSchema> = async () => {
    try {
      await saveData();
      props.onSubmit();
    } catch (_error) {}
  };

  // Auto-save data every 30 seconds or when the user is idle for 5 seconds
  useAutoTriggerAction(saveData);

  const FORM_ID = "expertProfile";

  return (
    <Stack>
      <UserPhotoForm />
      <form
        id={FORM_ID}
        name="expertProfile"
        onSubmit={handleSubmit(onSubmit)}
        className={props.className}
      >
        <Flex className={styles.formContent}>
          <SimpleGrid cols={{ lg: 2 }}>
            <TextInput
              label="First name"
              placeholder="First name"
              autoFocus
              error={formState.errors.firstName?.message}
              {...register("firstName")}
            />
            <TextInput
              label="Last name"
              placeholder="Last name"
              error={formState.errors.lastName?.message}
              {...register("lastName")}
            />
          </SimpleGrid>
          <SimpleGrid cols={{ lg: 2 }}>
            <NativeSelect
              label="Country"
              error={formState.errors.country?.message}
              {...register("country", {
                // Immediately save the data when the country is selected
                onChange: saveData,
              })}
            >
              <option value="" disabled>
                Please select
              </option>
              {countryList.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </NativeSelect>
            <TextInput
              label="City"
              placeholder="City"
              required={false}
              error={formState.errors.city?.message}
              {...register("city")}
            />
          </SimpleGrid>
          <TextInput
            label="LinkedIn"
            placeholder="https://www.linkedin.com/in/username/"
            error={formState.errors.linkedIn?.message}
            {...register("linkedIn")}
          />
          <div>
            <Text fw={600} mb={8}>
              Expertise summary
            </Text>
            <Text mb={8} className={styles.secondaryText}>
              Please write 3-5 sentences about the expertise you can offer to
              clients
            </Text>
            <TextArea
              minRows={4}
              autosize
              placeholder="Tell us about yourself and your expertise"
              error={formState.errors.summary?.message}
              {...register("summary")}
            />
            <Text c={summaryLength <= 600 ? "gray.6" : "red"} fz="xs">
              {summaryLength}/600 characters
            </Text>
          </div>
        </Flex>
      </form>
      <Flex
        className={clsx(
          styles.formControls,
          props.withStickyControls && styles.withStickyControls,
        )}
      >
        <Button
          type="submit"
          form={FORM_ID}
          variant={props.onSubmitText === "Close" ? "outline" : ""}
        >
          {props.onSubmitText}
        </Button>
        {props.onBack && (
          <Button
            variant="transparent"
            onClick={props.onBack}
            className={styles.backButton}
          >
            Back
          </Button>
        )}
      </Flex>
    </Stack>
  );
};

export { ExpertProfileForm };
