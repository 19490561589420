import { isProfileCompleted } from "@/components/organisms/ClientOnboarding/ClientProfileForm";
import { isCompanyInfoCompleted } from "@/components/organisms/ClientOnboarding/CompanyInfoForm";
import { isITSetupCompleted } from "@/components/organisms/ClientOnboarding/ITSetupForm";
import { useAuth } from "@/hooks/use-auth";
import { useClientSoftware } from "@/hooks/use-software";
import { ClientNextStepCallout } from "@/screens/documentation/ClientNextStepCallout";

export function ClientOnboardingCallout() {
  const { user } = useAuth();
  const { data: softwares } = useClientSoftware();

  if (!user) {
    return null;
  }

  const completedAllSteps =
    Boolean(softwares?.length) &&
    isProfileCompleted(user) &&
    isCompanyInfoCompleted(user) &&
    isITSetupCompleted(user);

  if (completedAllSteps) return null;

  return (
    <div className="w-dvw lg:hidden">
      <div className="m-6 mt-0 rounded-lg border border-[#0065F2] border-solid">
        <ClientNextStepCallout
          hasSoftware={Boolean(softwares?.length)}
          isProfileSetupDone={isProfileCompleted(user)}
          isCompanyInfoDone={isCompanyInfoCompleted(user)}
          isITSetupDone={isITSetupCompleted(user)}
        />
      </div>
    </div>
  );
}
