import {
  faCalendar,
  faGlobe,
} from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import { faCircleCheck } from "@awesome.me/kit-af809b8b43/icons/classic/solid";
import {
  Button,
  Divider,
  Flex,
  Modal,
  Stack,
  Text,
} from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns-tz";
import { useEffect, useRef, useState } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";

import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { UserProfile } from "@/components/organisms/UserProfile/UserProfile";
import { useBookingList, useNewBookedSession } from "@/hooks/use-booking";
import { useExpert } from "@/hooks/use-expert";

export type ScheduledEvent = {
  event: "calendly.event_scheduled";
  payload: {
    event: {
      uri: string;
    };
    invitee: {
      uri: string;
    };
  };
};

function CalendlyWidget(props: {
  calendlySchedulingURL: string;
  onConfirm: (newEvent: ScheduledEvent) => void;
}) {
  useCalendlyEventListener({
    onEventScheduled: (event) => props.onConfirm(event.data),
  });

  return (
    <InlineWidget
      url={props.calendlySchedulingURL}
      iframeTitle="Book a session using Calendly"
      styles={{ height: "600px", marginInline: "-24px" }}
      pageSettings={{
        primaryColor: "#7950f2", // Guidestack purple
        textColor: "#1D293A",
        backgroundColor: "transparent",
        hideEventTypeDetails: true,
        hideLandingPageDetails: true,
      }}
    />
  );
}

// Display emails in a human-friendly way
function displayEmails(emails: string[]) {
  // email@domain.com
  if (emails.length === 1) {
    return `${emails[0]}.`;
  }

  // email1@domain.com, and email2@domain.com
  if (emails.length === 2) {
    return `${emails[0]} and ${emails[1]}.`;
  }

  // email1@domain.com, email2@domain.com, ..., and @emailN@domain.com
  return `${emails.slice(0, -1).join(", ")}, and ${emails.slice(-1)[0]}.`;
}

function SessionDetails(props: {
  expertIdentifier: string;
  calendlyEventUri: string;
  calendlyInviteeUri: string;
}) {
  const expert = useExpert({ identifier: props.expertIdentifier });

  // Relies on Calendly
  const newBookedSession = useNewBookedSession(
    expert.data && {
      expertId: expert.data.id,
      inviteeUri: props.calendlyInviteeUri,
      eventUri: props.calendlyEventUri,
    },
  );

  // Fallback: If fetching the new booking using the Calendly API fails, we try to fetch the booking from the booking list
  const bookingList = useBookingList({ enabled: newBookedSession.isError });
  const retryCounter = useRef(3);

  const booking =
    newBookedSession.data ||
    bookingList?.data?.find(
      (booking) => booking.calendlyInviteeUri === props.calendlyInviteeUri,
    );

  useEffect(() => {
    if (!booking && newBookedSession.isError && !bookingList.isLoading) {
      setTimeout(() => {
        retryCounter.current -= 1;
        bookingList.refetch();
      }, 3000);
    }
  }, [
    booking,
    newBookedSession.isError,
    bookingList.isLoading,
    bookingList.refetch,
  ]);

  if (
    newBookedSession.isLoading ||
    bookingList.isLoading ||
    expert.isLoading ||
    (!booking && retryCounter.current) // When no booking is found and there are still retries left
  ) {
    return <Skeleton />;
  }

  if (bookingList.isError || expert.isError || !booking) {
    return (
      <Text className="text-[--mantine-color-gray-6]">
        Failed to load session details.
      </Text>
    );
  }

  const messageToExpert = booking?.additionalInfo.questionAndAnswers[0]?.answer;

  return (
    <>
      <Text>
        We sent a calendar invite to{" "}
        {displayEmails([
          booking.calendlyClientEmail,
          ...booking.calendlyGuestEmails,
        ])}
      </Text>
      <Stack className="gap-2 rounded border border-[--mantine-color-gray-3] border-solid p-4">
        <UserProfile user={expert.data || {}} showFullProfileOnClick={false} />
        <div className="flex text-[--mantine-color-gray-6]">
          <FontAwesomeIcon icon={faCalendar} className="mt-0.5 mr-2 size-5" />
          <span>
            {format(new Date(booking.sessionStartTime), "HH:mm", {
              timeZone: booking.inviteeTimezone,
            })}{" "}
            -{" "}
            {format(
              new Date(booking.sessionEndTime),
              "HH:mm, EEEE MMMM dd, yyyy",
              {
                timeZone: booking.inviteeTimezone,
              },
            )}
          </span>
        </div>
        <div className="flex text-[--mantine-color-gray-6]">
          <FontAwesomeIcon icon={faGlobe} className="mt-0.5 mr-2 size-5" />
          <span>{booking.inviteeTimezone}</span>
        </div>
        <Divider />
        <Text className="font-medium">Message to expert</Text>
        <Text>{messageToExpert || "None"}</Text>
      </Stack>
    </>
  );
}

type DialogProps = {
  expertIdentifier: string;
  calendlySchedulingUrl: string;
  opened: boolean;
  onClose: () => void;
};

export function CalendlyDialog(props: DialogProps) {
  const [scheduledEvent, setScheduledEvent] = useState<ScheduledEvent | null>(
    null,
  );

  useEffect(() => {
    if (props.opened) {
      setScheduledEvent(null);
    }
  }, [props.opened]);

  return (
    <Modal
      title={
        scheduledEvent ? (
          <>
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="mr-2 text-[--mantine-color-green-6]"
            />
            <Text span className="font-semibold text-xl/normal">
              Confirmed
            </Text>
          </>
        ) : (
          <Text span className="font-semibold text-xl/normal">
            Book a session
          </Text>
        )
      }
      opened={props.opened}
      onClose={props.onClose}
    >
      <Stack>
        {scheduledEvent ? (
          <SessionDetails
            expertIdentifier={props.expertIdentifier}
            calendlyEventUri={scheduledEvent.payload.event.uri}
            calendlyInviteeUri={scheduledEvent.payload.invitee.uri}
          />
        ) : (
          <CalendlyWidget
            calendlySchedulingURL={props.calendlySchedulingUrl}
            onConfirm={(newEvent) => setScheduledEvent(newEvent)}
          />
        )}
        <Flex className="flex-col lg:flex-row-reverse">
          <Button variant="outline" onClick={props.onClose}>
            Close
          </Button>
        </Flex>
      </Stack>
    </Modal>
  );
}
