import { Button } from "@flpstudio/design-system";

type StepItemProps = {
  completed: boolean;
  description: string;
  link?: string;
};

export function StepItem({ completed, description, link }: StepItemProps) {
  return (
    <li className="flex items-center justify-between gap-2">
      {description}
      {completed ? (
        <Button className="min-w-[74px]" disabled variant="outline">
          Done
        </Button>
      ) : (
        <Button
          className="min-w-[74px]"
          component="a"
          variant="outline"
          href={link}
        >
          Start
        </Button>
      )}
    </li>
  );
}
