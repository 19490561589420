import { useMediaQuery } from "@mantine/hooks";

const useScreenSize = () => {
  const useTemplateString = (minWidth: number) => `(min-width: ${minWidth}em)`;

  // NOTE: To keep the values in sync, make sure the values in the postcss.config.js file match the values here
  const isXs = useMediaQuery(useTemplateString(36));
  const isSm = useMediaQuery(useTemplateString(48));
  const isMd = useMediaQuery(useTemplateString(62));
  const isLg = useMediaQuery(useTemplateString(75));
  const isXl = useMediaQuery(useTemplateString(88));

  return { isXs, isSm, isMd, isLg, isXl };
};

export { useScreenSize };
