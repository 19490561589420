import { Button, Group, Modal, Text, Title } from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";

import { ExpertPreferencesForm } from "@/components/organisms/ExpertPreferencesForm/ExpertPreferencesForm";
import { useAuth } from "@/hooks/use-auth";
import { CLIENT_MATURITY } from "@/utils/client-maturity";
import { CLIENT_SIZE } from "@/utils/client-size";
import * as styles from "./Profile.module.css";

const ExpertPreferences = () => {
  const { user } = useAuth();
  const [opened, handlers] = useDisclosure();

  // Checking mandatory fields to determine if the profile is complete.
  const isComplete = Boolean(
    user?.expertProfile?.preferClientSizes?.length &&
      user?.expertProfile?.preferClientMaturities?.length,
  );

  return (
    <>
      <Group className={styles.sectionHeader}>
        <Title order={2} className={styles.title}>
          IT client preferences
        </Title>
        <Button variant="outline" onClick={handlers.open}>
          Edit
        </Button>
      </Group>
      {isComplete && (
        <>
          <Text>
            What size of client company are you most comfortable advising?
          </Text>
          <Text className={styles.secondaryText}>
            {CLIENT_SIZE.filter((size) =>
              user?.expertProfile?.preferClientSizes?.includes(size.value),
            )
              .map((size) =>
                size.value !== "CLIENT_SIZE_JUST_ME"
                  ? `${size.label} employees`
                  : size.label,
              )
              .join(", ")}
          </Text>
          <br />
          <Text>
            What stage of IT maturity are you able to support in client
            organizations?
          </Text>
          {CLIENT_MATURITY.filter((maturity) =>
            user?.expertProfile?.preferClientMaturities?.includes(
              maturity.value,
            ),
          ).map((maturity) => (
            <Text key={maturity.value} className={styles.secondaryText}>
              {maturity.label}
            </Text>
          ))}
        </>
      )}
      {!isComplete && (
        <>
          <Text className={styles.secondaryText}>
            Please add your IT client preferences. This information will help us
            match you with potential clients.
          </Text>
        </>
      )}
      <Modal
        opened={opened}
        onClose={() => {
          /* noop */
        }}
        title="IT client preferences"
      >
        <ExpertPreferencesForm
          onSubmitText="Close"
          onSubmit={handlers.close}
          withStickyControls
        />
      </Modal>
    </>
  );
};

export { ExpertPreferences };
