import { useMutation } from "@tanstack/react-query";
import axios, { type AxiosError } from "axios";

type StripePaymentCreateVariables = {
  successReturnUrl: string;
  cancelReturnUrl: string;
};

type StripePaymentCreateResponse = {
  paymentFormUrl: string;
};

export function stripePaymentCreateUrl() {
  return "/payment/stripe";
}

export function useStripePaymentCreate() {
  return useMutation<
    StripePaymentCreateResponse,
    AxiosError,
    StripePaymentCreateVariables
  >({
    mutationFn: async (variables) => {
      const { data } = await axios.post(stripePaymentCreateUrl(), variables);
      return data;
    },
  });
}
