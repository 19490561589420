import {
  Navigate,
  type RouteObject,
  createBrowserRouter,
} from "react-router-dom";

// Containers
import { Authenticated } from "@/screens/authenticated/Authenticated";

// screens
import { Auth } from "@/screens/auth/Auth";
import { EditDocument } from "@/screens/edit-document/EditDocument";
import { NotFound } from "@/screens/not-found/NotFound";
import { ClientOnboarding } from "@/screens/onboarding/ClientOnboarding";
import { ExpertOnboarding } from "@/screens/onboarding/ExpertOnboarding";
import { PostSSOAgreement } from "@/screens/post-sso-agreement/PostSSOAgreement";
import { PrivacyPolicy } from "@/screens/privacy-policy/PrivacyPolicy";
import { Profile } from "@/screens/profile/Profile";
import { TermsOfService } from "@/screens/terms-of-service/TermsOfService";
import { UserTypeSelection } from "@/screens/user-type-selection/UserTypeSelection";

// sub-screens/components of screens
import { SignIn } from "@/components/organisms/SignIn/SignIn";

import { paths } from "./paths";

// Accessible only when logged in
const privateRoutes: RouteObject[] = [
  {
    path: paths.root,
    element: <Authenticated hideNavigationBar />,
    children: [
      {
        path: paths.usageAgreement,
        element: <PostSSOAgreement />,
      },
      {
        path: paths.userTypeSelection,
        element: <UserTypeSelection />,
      },
      {
        path: paths.onboarding.client,
        element: <ClientOnboarding />,
      },
      {
        path: paths.onboarding.expert,
        element: <ExpertOnboarding />,
      },
      {
        path: paths.profile.documents.edit,
        element: <EditDocument />,
      },
    ],
  },
  {
    path: paths.root,
    element: <Authenticated />,
    children: [
      {
        index: true,
        element: <Navigate to={paths.documentation.root} replace />,
      },
      {
        path: paths.profile.root,
        element: <Profile />,
      },
      {
        path: paths.profile.documents.root,
        element: <Profile />,
      },
      {
        path: paths.profile.documents.review,
        element: <Profile />,
      },
      {
        path: paths.profile.documents.published,
        element: <Profile />,
      },
      {
        path: paths.profile.documents.declined,
        element: <Profile />,
      },
      {
        path: paths.documentation.root,
        lazy: () => import("@/screens/documentation/DocumentDashboard"),
      },
      {
        path: paths.documentation.viewer,
        lazy: () => import("@/screens/documentation/DocumentViewer"),
      },
      {
        path: paths.experts.root,
        lazy: () => import("@/screens/experts/Experts"),
      },
      {
        path: paths.experts.profile,
        lazy: () => import("@/screens/expert/Expert"),
      },
      {
        path: paths.sessions.root,
        lazy: () => import("@/screens/sessions/Sessions"),
      },
      {
        path: paths.sessions.completed,
        lazy: () => import("@/screens/sessions/Sessions"),
      },
    ],
  },
];

// Accessible to both logged-in and not logged-in users
const publicRoutes: RouteObject[] = [
  {
    path: paths.login,
    element: <Auth />,
    children: [{ index: true, element: <SignIn /> }],
  },
  {
    path: paths.privacyPolicy,
    element: <PrivacyPolicy />,
  },
  {
    path: paths.termsOfService,
    element: <TermsOfService />,
  },
  {
    path: paths.expertTermsOfService,
    lazy: () =>
      import("@/screens/expert-terms-of-service/ExpertTermsOfService"),
  },
  {
    path: paths.notFound,
    element: <NotFound />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const router = createBrowserRouter([...publicRoutes, ...privateRoutes]);

export { router };
