/**
 * It's possible to have multiple categories with the same name.
 * The reason is that a category can have different attributes, e.g. subcategories and prompts.
 * We need to normalize the categories to avoid duplicates and simplify the rendering.
 *
 * In the end, we want to have an array of objects like this:
 * [ { name: "Category Name", idSet: { "id1", "id2" } } ]
 */

import type { SoftwareCategory } from "types";

type NormalizeCategory = {
  name: SoftwareCategory["category"];
  idSet: Set<SoftwareCategory["id"]>;
};

export function normalizeCategories(categories: SoftwareCategory[]) {
  if (!categories[0]) {
    return [];
  }

  const mergedResult: NormalizeCategory[] = [];

  let currentCategory: NormalizeCategory = {
    name: categories[0].category,
    idSet: new Set([categories[0].id]),
  };

  for (const cat of categories) {
    if (cat.category === currentCategory.name) {
      currentCategory.idSet.add(cat.id);
    } else {
      mergedResult.push(currentCategory);
      currentCategory = {
        name: cat.category,
        idSet: new Set([cat.id]),
      };
    }
  }

  mergedResult.push(currentCategory);

  return mergedResult;
}
