import { clsx } from "clsx/lite";
import { useId } from "react";

type Props = {
  repeat?: number;
  className?: string;
};
export function Skeleton({ repeat = 1, className = "" }: Props) {
  const skeletons = Array.from({ length: repeat });

  return (
    <div className={clsx("flex flex-col gap-10", className)}>
      {skeletons.map(() => (
        <SkeletonItem key={useId()} />
      ))}
    </div>
  );
}

function SkeletonItem() {
  return (
    <div className="flex animate-pulse flex-col gap-3">
      <div className="h-4 rounded bg-slate-200" />
      <div className="h-2 rounded bg-slate-200" />
      <div className="h-2 rounded bg-slate-200" />
      <div className="h-2 w-1/2 rounded bg-slate-200" />
    </div>
  );
}
