import { Button, Flex, Stack, Text } from "@flpstudio/design-system";

import * as styles from "./ExpertIncompleteProfileSetup.module.css";

interface Props {
  className?: string;
  onCancel: () => void;
  onStart: () => void;
}

const ExpertIncompleteProfileSetup = (props: Props) => (
  <Stack>
    <Text>
      Before creating your first document, please take a few minutes to finish
      your profile setup.
    </Text>
    <Flex className={styles.formControls}>
      <Button onClick={props.onStart}>Start</Button>
      <Button variant="outline" onClick={props.onCancel}>
        Cancel
      </Button>
    </Flex>
  </Stack>
);

export { ExpertIncompleteProfileSetup };
