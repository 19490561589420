import "@flpstudio/design-system/global.css";
import { Notifications } from "@flpstudio/design-system";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import { StrictMode, useEffect } from "react";
import {
  RouterProvider,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { useAuth } from "./hooks/use-auth";
import { AuthProvider } from "./providers/AuthProvider";
import { router } from "./routes/router";
import { ThemeProvider, theme } from "./styles/theming";

import "./App.css";
import { showNotification } from "@/utils/notification";

axios.defaults.baseURL = import.meta.env.VITE_BASE_URL;
axios.defaults.withCredentials = true;

Sentry.init({
  enabled: import.meta.env.MODE !== "development",
  environment: import.meta.env.MODE,
  dsn: "https://4d4b0142277967832198a3dbcb77b45c@o4507610489421824.ingest.us.sentry.io/4507581522575360",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
  // TODO: Add production target
  tracePropagationTargets:
    (import.meta.env.MODE === "staging" && [
      /^https:\/\/api\.streamline\.dev\//,
    ]) ||
    [],
  // Session Replay
  // NOTE: When there are more users, we can reduce the sample rate.
  // replaysSessionSampleRate: import.meta.env.MODE === "staging" ? 1.0 : 0.1,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: () =>
        showNotification({
          title: "Sorry we couldn't complete your request",
          message: "Please try again in a moment.",
          color: "error",
        }),
    },
  },
});

const App = () => {
  useEffect(() => {
    if (window) {
      // https://vitejs.dev/guide/build.html#load-error-handling
      const reloadedKey = "reloadForPreLoadErr";
      window.sessionStorage.removeItem(reloadedKey);

      const preloadErrorHandler = (event: Event) => {
        event.preventDefault();
        if (!window.sessionStorage.getItem(reloadedKey)) {
          window.location.reload();
          window.sessionStorage.setItem(reloadedKey, "reloaded");
        }
      };

      window.addEventListener("vite:preloadError", preloadErrorHandler);

      return () => {
        window.removeEventListener("vite:preloadError", preloadErrorHandler);
      };
    }
  }, []);

  return (
    <StrictMode>
      <Sentry.Profiler>
        <Sentry.ErrorBoundary fallback={<p>An error has occurred.</p>}>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <ThemeProvider theme={theme}>
                <Notifications />
                <AppWithRouter />
              </ThemeProvider>
            </AuthProvider>
          </QueryClientProvider>
        </Sentry.ErrorBoundary>
      </Sentry.Profiler>
    </StrictMode>
  );
};

const AppWithRouter = () => {
  const { user } = useAuth();

  return (
    // Prevent the router from rendering until the user is known.
    // This prevents the app from "flashing" while trying to render the wrong screen.
    user !== undefined && (
      <RouterProvider router={router} future={{ v7_startTransition: true }} />
    )
  );
};

const appRoot = document.getElementById("streamline-app") as HTMLDivElement;

export { App, appRoot };
