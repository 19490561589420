import {
  faBell,
  faCheckCircle,
  faCircleExclamation,
  faTriangleExclamation,
} from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import { notifications } from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type ReactNode, createElement } from "react";

type STATUS = "primary" | "error" | "success" | "warning";

type ShowNotificationOptions = {
  title: ReactNode;
  message: ReactNode;
  color?: STATUS;
  withCloseButton?: boolean;
  autoClose?: number | boolean;
};

const COLOR_MAP = {
  primary: "primary-filled",
  error: "error",
  success: "green-6",
  warning: "orange-6",
};

const ICON_MAP = {
  primary: faBell,
  error: faTriangleExclamation,
  success: faCheckCircle,
  warning: faCircleExclamation,
};

export function showNotification(options: ShowNotificationOptions) {
  notifications.show({
    title: options.title,
    message: options.message,
    color: `var(--mantine-color-${COLOR_MAP[options.color ?? "primary"]})`,
    icon: createElement(FontAwesomeIcon, {
      icon: ICON_MAP[options.color ?? "primary"],
    }),
    withCloseButton: options.withCloseButton ?? false,
    autoClose: options.autoClose ?? 2000,
  });
}
