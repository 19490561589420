import { Button, Group, Modal, Text, Title } from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";

import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import {
  CompanyInfoForm,
  WORK_MODE,
  isCompanyInfoCompleted,
} from "@/components/organisms/ClientOnboarding/CompanyInfoForm";
import { useAuth } from "@/hooks/use-auth";
import { useIndustries } from "@/hooks/use-industry";
import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";
import { CLIENT_SIZE } from "@/utils/client-size";

export function EditableCompanyInfo() {
  const { user } = useAuth();
  const { isModalOpen } = useProfilePageSearchParams();
  const [opened, handlers] = useDisclosure(isModalOpen("companyInfo"));
  const { data: industryList, isLoading: isLoadingIndustryList } =
    useIndustries();

  if (!user) return null;

  const industry = industryList?.find(
    (ind) => ind.id === user.clientProfile?.company?.industryId,
  )?.name;

  return (
    <>
      <Group className="mb-4 justify-between">
        <Title order={4}>Company info</Title>
        <Button variant="outline" onClick={handlers.open}>
          Edit
        </Button>
      </Group>
      {isCompanyInfoCompleted(user) ? (
        <dl className="m-0">
          <dt>Primary industry</dt>
          <dd className="mt-1 mb-4 ml-0 text-[color:--mantine-color-gray-6]">
            {isLoadingIndustryList ? <Skeleton /> : industry}
          </dd>

          <dt>What does your company do?</dt>
          <dd className="mt-1 mb-4 ml-0 text-[color:--mantine-color-gray-6]">
            {user.clientProfile?.company?.summary}
          </dd>

          <dt>Employee count</dt>
          <dd className="mt-1 mb-4 ml-0 text-[color:--mantine-color-gray-6]">
            {
              CLIENT_SIZE.find(
                (s) => s.value === user.clientProfile?.company?.size,
              )?.label
            }
          </dd>

          <dt>Working environment</dt>
          <dd className="mt-1 mb-4 ml-0 text-[color:--mantine-color-gray-6]">
            {
              WORK_MODE.find(
                (s) => s.value === user.clientProfile?.company?.workMode,
              )?.label
            }
          </dd>
        </dl>
      ) : (
        <Text className="text-[color:--mantine-color-gray-6]">
          This information will help us recommend IT documentation and experts.
          It will be visible only to you and any experts you directly book
          consultations with.
        </Text>
      )}
      <Modal
        opened={opened}
        onClose={() => {
          /* noop */
        }}
        title="Company info"
      >
        <CompanyInfoForm
          onSubmitText="Close"
          onSubmit={handlers.close}
          withStickyControls
        />
      </Modal>
    </>
  );
}
