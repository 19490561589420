import { Navigate, Outlet } from "react-router-dom";

import { NavigationBar } from "@/components/organisms/NavigationBar/NavigationBar";
import { ViewExpertProfileDialog } from "@/components/organisms/ViewExpertProfileDialog/ViewExpertProfileDialog";
import { useAuth } from "@/hooks/use-auth";
import { paths } from "@/routes/paths";

type Props = {
  hideNavigationBar?: boolean;
};

export function Authenticated({ hideNavigationBar }: Props) {
  const { user } = useAuth();

  const areAgreementsAccepted = !!user?.termsAcceptedAt;

  // With explorers, both expertProfile and clientProfile will be null.
  const isExplorer =
    user?.expertProfile === null && user?.clientProfile === null;
  const isUserTypeKnown = user?.expertProfile || user?.clientProfile;

  let redirectTo: string | null = null;
  if (!user) {
    redirectTo = paths.login;
  } else if (!areAgreementsAccepted) {
    redirectTo = paths.usageAgreement;
  } else if (!isExplorer && !isUserTypeKnown) {
    redirectTo = paths.userTypeSelection;
  }

  return (
    <>
      {!hideNavigationBar && <NavigationBar />}
      <Outlet />
      {redirectTo && <Navigate to={redirectTo} replace />}
      <ViewExpertProfileDialog />
    </>
  );
}
