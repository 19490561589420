import { faCircle } from "@awesome.me/kit-af809b8b43/icons/classic/light";
import { faCircleCheck } from "@awesome.me/kit-af809b8b43/icons/classic/solid";
import { Group, UnstyledButton } from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generatePath } from "react-router-dom";

import { paths } from "@/routes/paths";
import type { Software } from "types";

type SoftwareGroupProps = {
  list: Software[];
  showStatus?: boolean;
};

export function SoftwareGroup({
  list,
  showStatus = false,
}: SoftwareGroupProps) {
  if (!list.length) {
    return null;
  }

  const publishedDocUrlTitleMap =
    list.reduce(
      (acc, software) => {
        if (software.documents?.[0]) {
          acc[software.id] = generatePath(paths.documentation.viewer, {
            urlSlug: software.documents[0].urlTitle,
          });
        }
        return acc;
      },
      {} as Record<string, string>,
    ) ?? {};

  return (
    <Group className="gap-2">
      {list.map(({ website, icon, name, id, documents }) => (
        <UnstyledButton
          key={id}
          component="a"
          {...(publishedDocUrlTitleMap[id]
            ? {}
            : { target: "_blank", rel: "noopener noreferrer" })} // open external site in new tab
          href={publishedDocUrlTitleMap[id] || website}
          className="flex flex-nowrap items-center gap-2 rounded-[--mantine-radius-default] border border-[--mantine-color-gray-4] border-solid p-2"
        >
          {icon && <img src={icon} alt={name} className="size-5" />}
          <span className="line-clamp-1">{name}</span>
          {showStatus &&
            (documents?.length ? (
              <FontAwesomeIcon
                className="text-[--mantine-color-green-filled]"
                icon={faCircleCheck}
              />
            ) : (
              <FontAwesomeIcon
                className="text-[--mantine-color-gray-4]"
                icon={faCircle}
              />
            ))}
        </UnstyledButton>
      ))}
    </Group>
  );
}
