import { Stack, Text, Title, type TitleProps } from "@flpstudio/design-system";
import { useInView } from "react-intersection-observer";

type Props = {
  onScrolledToBottom?: () => void;
  headingOrder?: number;
};

export function ExpertTermsOfService(props: Props) {
  const { ref } = useInView({
    onChange: (inView) => {
      if (inView) {
        props.onScrolledToBottom?.();
      }
    },
    skip: !props.onScrolledToBottom,
  });

  // TODO: Replace with real text
  return (
    <>
      <Stack>
        <Title
          order={(props.headingOrder as TitleProps["order"]) ?? 1}
          className="text-base/normal"
        >
          Guidestack Expert Terms of Service
        </Title>
        <Text>
          In high winds, Chimecho cries as it hangs from a tree branch or the
          eaves of a building using a suction cup on its head. This Pokémon
          plucks berries with its long tail and eats them. Slakoth’s heart beats
          just once a minute. Whatever happens, it is content to loaf around
          motionless. It is rare to see this Pokémon in motion.As its energy,
          Togepi uses the positive emotions of compassion and pleasure exuded by
          people and Pokémon. This Pokémon stores up feelings of happiness
          inside its shell, then shares them with others.
        </Text>
        <Text>
          This Pokémon is shaped like ancient writing. It is a mystery as to
          which came first, the ancient writings or the various Unown. Research
          into this topic is ongoing but nothing is known. Bulbasaur can be seen
          napping in bright sunlight. There is a seed on its back. By soaking up
          the sun’s rays, the seed grows progressively larger. Skitty is known
          to chase around playfully after its own tail. In the wild, this
          Pokémon lives in holes in the trees of forests. It is very popular as
          a pet because of its adorable looks.
        </Text>
        <Text>
          Meditite heightens its inner energy through meditation. It survives on
          just one berry a day. Minimal eating is another aspect of this
          Pokémon’s training.They spew flames mixed with poison to finish off
          their opponents. They divvy up their prey evenly among the members of
          their pack. It changes its hue to blend into its surroundings. If no
          one takes notice of it for too long, it will pout and never reveal
          itself.
        </Text>
        <Text>
          Bayleef’s neck is ringed by curled-up leaves. Inside each tubular leaf
          is a small shoot of a tree. The fragrance of this shoot makes people
          peppy. The bunches of fruit growing around the necks of Tropius in
          Alola are especially sweet compared to those in other regions.It was
          born from sludge on the ocean floor. In a sterile environment, the
          germs within its body can’t multiply, and it dies.
        </Text>
        <Text>
          Although they enjoy comparing their strength, they’re also kind. They
          value etiquette, praising opponents they battle. Deoxys emerged from a
          virus that came from space. It is highly intelligent and wields
          psychokinetic powers. This Pokémon shoots lasers from the crystalline
          organ on its chest. Farfetch’d is always seen with a stalk from a
          plant of some sort. Apparently, there are good stalks and bad stalks.
          This Pokémon has been known to fight with others over stalks.
        </Text>
      </Stack>
      {/* bottom of the page tracker */}
      <div ref={ref} />
    </>
  );
}
