const randomColors = [
  "rgb(155,200,227)",
  "rgb(77, 156, 185)",
  "rgb(17,47,69)",
  "rgb(243, 186, 65)",
  "rgb(208,143,25)",
];

function hashCode(str: string) {
  return str
    .split("")
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
      0,
    );
}
const randomProfileColorPicker = (email = ""): string => {
  const idx = Math.abs(hashCode(email)) % randomColors.length;
  return randomColors[idx] || "rgb(155,200,227)";
};

export { randomProfileColorPicker };
