import { useMutation } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { documentQueryKey } from "./use-documents";

export type LikeGroupType = "COMMENT" | "DOCUMENT";

export function useLikeMutation({
  type,
  groupId,
}: {
  type: LikeGroupType;
  groupId: string;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: { action: "post" | "delete" }) => {
      const request = axios[variables.action];

      return request(`/like-groups/${groupId}`, {
        type,
      });
    },

    onSuccess: () => {
      if (type === "DOCUMENT") {
        queryClient.invalidateQueries({
          queryKey: [documentQueryKey.publishedDocument],
        });
      }

      if (type === "COMMENT") {
        queryClient.invalidateQueries({
          queryKey: ["comments"],
        });
      }
    },
  });
}
