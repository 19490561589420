import {
  type AuthenticationResult,
  type BrowserAuthError,
  type Configuration,
  PublicClientApplication,
} from "@azure/msal-browser";

interface InitiateSSOProps {
  onSuccess?: (response: AuthenticationResult) => void;
  onCancel?: () => void;
}

const config: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_MS_SSO_CLIENT_ID,
    authority: "https://login.microsoftonline.com/common",
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "memoryStorage",
    storeAuthStateInCookie: false,
  },
  system: {},
};

const scopes: string[] = ["user.read", "email", "openid", "profile"];

const useMicrosoftSSO = () => {
  const msalInstance = new PublicClientApplication(config);

  const initiateSSO = async (props: InitiateSSOProps) => {
    await msalInstance.initialize();

    try {
      const response = await msalInstance.loginPopup({ scopes });
      if (response) {
        props.onSuccess?.(response);
      }
    } catch (error) {
      // Typecast the error to BrowserAuthError
      const errorObj = error as BrowserAuthError;
      console.error(`Microsoft SSO: ${errorObj.errorMessage}`);
      props.onCancel?.();
    }
  };

  return {
    initiateSSO,
  };
};

export { useMicrosoftSSO };
